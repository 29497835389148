import { CountryDropdown, TextField } from '@yembo/yemblocks-core';
import { ChangeEvent, useContext } from 'react';
import { InsuredContext } from '../../_contexts/InsuredProvider';

export const PropertyDetails = (): JSX.Element => {
  const { insuredFormData, dispatchInsuredFormData } = useContext(InsuredContext);
  const { company, property } = insuredFormData;
  const { streetAddress, addressLine2, city, state, zip, country } = property;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatchInsuredFormData({ property: { ...property, [name]: value } });
  };

  return (
    <div className='section location-section'>
      <p className='bold header'>Address</p>
      <div className='inputs-wrapper'>
        <CountryDropdown
          isRequired
          selectedCountry={country ?? company?.defaultCountry}
          onSelect={({ value }: { value: string }) =>
            dispatchInsuredFormData({ property: { ...property, country: value } })
          }
        />
        <TextField
          required
          label='Street'
          value={streetAddress}
          name='streetAddress'
          onChange={onChange}
          autoComplete='nope'
        />
        <TextField
          label='Apartment, Suite, etc.'
          value={addressLine2}
          name='addressLine2'
          onChange={onChange}
          autoComplete='nope'
        />
        <div className='state-wrapper'>
          <TextField required label='City' value={city} name='city' onChange={onChange} autoComplete='nope' />
          <TextField
            required
            className='state'
            label='State'
            value={state}
            name='state'
            onChange={onChange}
            autoComplete='nope'
          />
          <TextField
            required
            className='zip'
            label='Zip Code'
            value={zip}
            name='zip'
            onChange={onChange}
            autoComplete='nope'
          />
        </div>
      </div>
    </div>
  );
};
