import { YemboLogo } from '@yembo/yemblocks-core';
import { PageType } from '../../index';
interface Props {
  pageType: PageType;
}

export const AuthHeader = ({ pageType }: Props): JSX.Element => {
  if (pageType === 'password')
    return (
      <div className='auth-header'>
        <div className='header'>
          <h1>Welcome to</h1>
          <YemboLogo />
        </div>
        <p className='auth-caption'>Set your password to get started</p>
      </div>
    );
  if (pageType === 'phone')
    return (
      <div className='auth-header'>
        <div className='header'>
          <h1>See</h1>
          <YemboLogo />
          <h1>in action!</h1>
        </div>
        <p className='auth-caption'>Enter your phone number</p>
      </div>
    );
  if (pageType === 'success')
    return (
      <div className='auth-header'>
        <div className='header'>
          <h1>And you're off!</h1>
        </div>
        <p className='auth-caption'>
          Check your text messages for the inspection link (it may take a few seconds to arrive)
        </p>
      </div>
    );

  return (
    <div className='auth-header'>
      <div className='header'>
        <h1>Oh no!</h1>
      </div>
      <p className='auth-caption'>Your activation link is not valid. Please try again with a new link</p>
    </div>
  );
};
