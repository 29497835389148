import { Animation, Button } from '@yembo/yemblocks-core';

import { FC } from 'react';
type SuccessProps = {
  resultText: string;
  onViewCreated: () => void;
  viewButtonText: string;
  onActionClick: () => void;
  actionButtonText: string;
};

export const Success: FC<SuccessProps> = ({
  onViewCreated,
  viewButtonText,
  resultText,
  actionButtonText,
  onActionClick,
}): JSX.Element => {
  return (
    <div className='result-content'>
      <div className='animation-wrapper'>
        <Animation name='contact-success' loop={false} />
      </div>
      <div className='result-text'>{resultText}</div>
      <Button className='yb-primary-button result-main-action' text={viewButtonText} onClick={onViewCreated} />
      <Button className='yb-tertiary-button' text={actionButtonText} onClick={onActionClick} />
    </div>
  );
};
