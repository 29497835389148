import { Button, Modal, ModalHeader, ModalFooter } from '@yembo/yemblocks-core';
import React, { useEffect, useState } from 'react';
import { canUseServiceWorker, registerValidSW, swUrl } from 'src/react/_sw/serviceWorkerRegistration';
import * as Messenger from 'src/react/_sw/Messanger';

const CHECK_FOR_UPDATE_INTERVAL = 15 * 60 * 1000; // 15 minutes
const RUN_UPDATE_TASK_INTERVAL = 4 * 60 * 1000; // 4 minutes
const RESET_CANCELED_STATUS_INTERVAL = 2 * 60 * 60 * 1000; // 2 hours
const START_TIMER_VALUE = 30; // 30 seconds

export type UpdateStatus = 'current' | 'canceled' | 'available';

export const UpdateChecker = (): JSX.Element | null => {
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(performance.now());
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>('current');
  const [timer, setTimer] = useState<number | null>(START_TIMER_VALUE);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Math.abs(lastUpdateTimestamp - performance.now()) < CHECK_FOR_UPDATE_INTERVAL) {
        return;
      }
      checkIfUpdateExist();
      setLastUpdateTimestamp(performance.now());
    }, RUN_UPDATE_TASK_INTERVAL);
    return (): void => clearInterval(intervalId);
  }, [lastUpdateTimestamp]);

  useEffect(() => {
    return Messenger.createMessageListener('update_ready', () => {
      setLastUpdateTimestamp(performance.now());
      setUpdateStatus('available');
    });
  }, [updateStatus]);

  useEffect(() => {
    if (updateStatus === 'canceled') {
      const timeoutId = setTimeout(() => {
        setUpdateStatus('available');
      }, RESET_CANCELED_STATUS_INTERVAL);
      return (): void => clearTimeout(timeoutId);
    }
  }, [updateStatus]);

  const handleClose = (): void => {
    setUpdateStatus('canceled');
  };

  const handleReload = (): void => {
    skipWaitingSw();
  };

  useEffect(() => {
    if (updateStatus !== 'available' || !timer) return;

    const timerId = setTimeout(() => {
      if (timer === 1) {
        handleReload();
      } else {
        setTimer(timer - 1);
      }
    }, 1000);

    const userActionHandler = () => setTimer(null);

    window.addEventListener('mousemove', userActionHandler);
    window.addEventListener('keydown', userActionHandler);
    window.addEventListener('touchstart', userActionHandler);

    return () => {
      clearTimeout(timerId);
      window.removeEventListener('mousemove', userActionHandler);
      window.removeEventListener('keydown', userActionHandler);
      window.removeEventListener('touchstart', userActionHandler);
    };
  }, [timer, updateStatus]);

  return updateStatus === 'available' ? (
    <Modal isDismissible={false} className='update-checker-modal'>
      <ModalHeader headerText='Update Available'>
        <p className='bold'>A new and improved version of Yembo is available!</p>
        <p className='modal-subheader-secondary-text'>Please reload — it takes only a few seconds.</p>
      </ModalHeader>
      <ModalFooter>
        <Button className='yb-tertiary-button' text='Cancel' onClick={handleClose} />
        <Button
          text={
            <span className='button-main-text'>
              <span>Reload</span>
              {timer && <span className='timer'> ({timer})</span>}
            </span>
          }
          onClick={handleReload}
        />
      </ModalFooter>
    </Modal>
  ) : null;
};

async function skipWaitingSw(): Promise<void> {
  const registration = await navigator.serviceWorker.register(swUrl);
  if (registration && registration.waiting) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });

    await registration.update();
  }
  window.location.reload();
}

function checkIfUpdateExist() {
  if (!canUseServiceWorker()) return;

  registerValidSW(swUrl, {
    onUpdate: () => {
      Messenger.sendMessage('update_ready', null);
    },
  });
}
