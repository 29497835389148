import { Icon } from '@yembo/yemblocks-core';
import { IconName } from '@yembo/yemblocks-core/dist/Icon/iconMap';
import { AnimatedLine } from '../AnimatedLine';

type StepProps = {
  iconName: IconName;
  isActive: boolean;
  isLineActive: boolean;
  isLineAppended: boolean;
};

export const Step = ({ iconName, isLineAppended, isActive, isLineActive }: StepProps): JSX.Element => (
  <div className='step-wrap'>
    <span className={`progress-bar-fill ${isActive ? 'active' : 'bot-active'}`}></span>
    <span className='circle'>
      <Icon name={iconName} stroke='white' />
    </span>
    {isLineAppended ? <AnimatedLine lineActive={isLineActive} /> : null}
  </div>
);
