import { Dropdown, LoadingSpinner } from '@yembo/yemblocks-core';
import { DropdownOption } from '@yembo/yemblocks-core/dist/Dropdown';
import { useContext } from 'react';
import { useQueryCompanies } from 'src/queries/companies';
import { InsuredContext } from '../../_contexts/InsuredProvider';

export const EmployeeDetails = (): JSX.Element => {
  const { data: companies, isLoading: isCompaniesLoading } = useQueryCompanies();

  const { insuredFormData, dispatchInsuredFormData } = useContext(InsuredContext);
  const { company } = insuredFormData;

  const getCompanyOptions = () => {
    if (!companies) return [];

    const options: DropdownOption[] = companies.map((company) => ({
      name: company.name,
      onClick: () => {
        dispatchInsuredFormData({ company });
      },
    }));

    return options;
  };

  const getCurrentCompany = () => {
    if (company) return company.name;

    return isCompaniesLoading ? <LoadingSpinner name='blue-spinner' label='Loading...' /> : null;
  };

  return (
    <div className='section employee-section'>
      <p className='bold header'>Company</p>
      <div className='inputs-wrapper'>
        <Dropdown
          placeholder='Select Company'
          label='Select Company'
          value={getCurrentCompany()}
          options={getCompanyOptions()}
        />
      </div>
    </div>
  );
};
