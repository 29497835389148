import browserLocale from 'browser-locale';
import { getUserAgent } from '@yembo/yemblocks-core/dist/_util';

export const snapshotDebugInfo = () => {
  const { os, browser } = getUserAgent();
  const locale = browserLocale();

  window.osInfo = { name: os.name ? os.name : '', version: os.version ? os.version : '' };
  window.browserInfo = { name: browser.name ? browser.name : '', version: browser.version ? browser.version : '' };
  window.resolution = `${window.screen.width * window.devicePixelRatio} x ${
    window.screen.height * window.devicePixelRatio
  }`;

  window.ramInfo = navigator.deviceMemory ? `At least ${navigator.deviceMemory} GB of RAM` : 'Unknown';
  window.locale = locale;
};
