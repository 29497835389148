const { host } = window.location;
const isLocalhost = host.startsWith('localhost:');
const isNgrok = host.includes('.ngrok.io');

export const intercomId = 'itvqnot4';

export const environment = (() => {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.exec(window.location.hostname)
  );

  const isNgrok = Boolean(window.location.hostname.endsWith('ngrok.io'));

  if (isLocalhost) {
    return 'localhost';
  }

  if (isNgrok) {
    return 'dev';
  }

  switch (window.location.hostname) {
    case 'app.kepler.dev.yembo.ai':
      return 'dev';
    case 'app.kepler.staging.yembo.ai':
      return 'staging';
    case 'kepler.yembo.ai':
    case 'insurance.yembo.ai':
      return 'prod';
    default:
      break;
  }
})();

export const isDevEnvironment =
  host === 'app.kepler.dev.yembo.ai' ||
  process.env.REACT_APP_API_ENV === 'dev' ||
  host === 'artisan.kepler.dev.yembo.ai';
export const isStagingEnvironment =
  host === 'app.kepler.staging.yembo.ai' || process.env.REACT_APP_API_ENV === 'staging';
export const isProdEnvironment = host === 'insurance.yembo.ai' || process.env.REACT_APP_API_ENV === 'prod';
export const isAnnotationEnvironment = host === 'annotation.yembo.ai' || process.env.REACT_APP_API_ENV === 'annotation';

export let isLogRocketEnabled = false;

export let isDeveloperMode = isLocalhost || isNgrok;

export const { apiUrl, s3BucketName, googlePlacesId, location } = ((): {
  apiUrl: string;
  s3BucketName: string;
  googlePlacesId: string;
  location: string;
} => {
  let apiUrl: string;
  let s3BucketName: string;
  let googlePlacesId: string;
  let location: string;

  if (isDevEnvironment) {
    apiUrl = 'https://api.kepler.dev.yembo.ai';
    s3BucketName = 'dev-yembo-kepler-s3';
    googlePlacesId = 'AIzaSyD12XXKq2s__ugeMFWfmNBCB1z0Acn56JA';
    location = 'us';
    isDeveloperMode = true;
  } else if (isStagingEnvironment) {
    apiUrl = 'https://api.kepler.staging.yembo.ai';
    s3BucketName = 'staging-2-kepler-s3';
    isLogRocketEnabled = true;
    googlePlacesId = 'AIzaSyD0Xg2ZGbs9iaRE7xTWTXpHO22PiwAjlkg';
    location = 'us';
  } else if (isProdEnvironment) {
    apiUrl = 'https://api-us.kepler.yembo.ai';
    s3BucketName = 'yembo-kepler-prod-yembo';
    isLogRocketEnabled = true;
    googlePlacesId = 'AIzaSyCKwyujNn8_zoE84AFOpGGYyNvGCvwAEwU';
    location = 'us';
  } else if (isAnnotationEnvironment) {
    apiUrl = 'https://api.kepler.annotation.yembo.ai';
    s3BucketName = 'prod-sg-yembo-kepler-annotation-yembo-kepler-annotation-s3';
    isLogRocketEnabled = true;
    googlePlacesId = 'AIzaSyD5qksRgl4bCD9ljChSZZcFlOBPwd0Eovc';
    location = 'sg';
  } else {
    throw new Error('Unrecognized API Environment');
  }

  return { apiUrl, s3BucketName, googlePlacesId, location };
})();

type Status = 'success' | 'error';

let googleMapsPromise: Promise<Status> | undefined;

export const installGoogleMaps = async () => {
  if (!googleMapsPromise) {
    const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${googlePlacesId}&libraries=places`;

    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = mapsUrl;
    googleMapsScript.type = 'text/javascript';
    googleMapsScript.async = true;
    googleMapsScript.defer = true;

    window.document.body.appendChild(googleMapsScript);

    googleMapsPromise = new Promise((resolve) => {
      const load = () => {
        googleMapsScript.dataset.loadStatus = 'success';
        resolve('success');
        googleMapsScript.removeEventListener('load', load);
        googleMapsScript.removeEventListener('error', error);
      };

      const error = () => {
        googleMapsScript.dataset.loadStatus = 'error';
        resolve('error');
        googleMapsScript.removeEventListener('load', load);
        googleMapsScript.removeEventListener('error', error);
      };

      googleMapsScript.addEventListener('load', load);
      googleMapsScript.addEventListener('error', error);
    });
  }

  return googleMapsPromise;
};

export const installPolyfill = (): void => {
  const polyfillScript = document.createElement('script');
  polyfillScript.src =
    'https://polyfill.io/v3/polyfill.min.js?features=Element.prototype.animate%2CObject.entries%2CObject.fromEntries%2CIntersectionObserver%2CIntersectionObserverEntry%2CElement.prototype.scroll%2CElement.prototype.scrollIntoView%2CResizeObserver';
  polyfillScript.type = 'text/javascript';
  polyfillScript.async = false;
  polyfillScript.defer = false;
  polyfillScript.crossOrigin = 'anonymous';

  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode?.insertBefore(polyfillScript, firstScript);
};

export const tinymceApiKey = 'i07irqg469ez2pc4x7wqvr8n1195pwm81lpye04no6i8x6li';
