import { TextField, TextFieldPhone } from '@yembo/yemblocks-core';
import { isValidEmail } from '@yembo/yemblocks-core/dist/_util';
import { ChangeEvent, useContext } from 'react';
import { checkValidatePhoneAPI } from 'src/queries/insured';
import { checkIsNameValid, InsuredContext } from '../../_contexts/InsuredProvider';

export const InsuredDetails = (): JSX.Element => {
  const { insuredState, insuredFormData, dispatchInsuredFormData, dispatchInsuredState } = useContext(InsuredContext);
  const { insured, company } = insuredFormData;
  const { isPhoneValid } = insuredState;
  const { id, givenName, familyName, phone, email } = insured;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatchInsuredFormData({ insured: { ...insured, [name]: value } });
  };

  const onPhoneUpdate = (phone: string, isPhoneValid: boolean) => {
    const value = phone === '+' ? '' : phone;
    dispatchInsuredFormData({ insured: { ...insured, phone: value } });
    dispatchInsuredState({ isPhoneValid });
  };

  return (
    <div className='customer-section section'>
      <p className='bold header'>Insured Information</p>
      <div className='inputs-wrapper'>
        <div className='inline-wrapper'>
          <TextField
            label='First Name'
            name='givenName'
            value={givenName}
            required
            onChange={onChange}
            errorMessage='First name is not valid'
            checkIsValid={(e) => checkIsNameValid(e.target.value)}
          />
          <TextField
            label='Last Name'
            name='familyName'
            value={familyName}
            required
            onChange={onChange}
            errorMessage='Last name is not valid'
            checkIsValid={(e) => checkIsNameValid(e.target.value)}
          />
        </div>
        <TextFieldPhone
          value={phone}
          isPhoneRequired={!email?.length || !isValidEmail(email)}
          onChangeCallback={onPhoneUpdate}
          onSubmitCallback={onPhoneUpdate}
          onPhoneCheck={
            company?.key ? async (phone) => await checkValidatePhoneAPI({ phone, companyKey: company.key }) : undefined
          }
        />
        <TextField
          label='Email'
          name='email'
          required={!phone?.length || !isPhoneValid}
          value={email}
          onChange={onChange}
          checkIsValid={(e) => (e.target.value.length !== 0 ? isValidEmail(email) : true)}
        />
        <div className='inline-wrapper'>
          <TextField label='Customer ID' name='id' value={id} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};
