import { InsuredDetails } from './InsuredDetails';
import { EmployeeDetails } from './EmployeeDetails';
import { PropertyDetails } from './PropertyDetails';

export const Form = (): JSX.Element => {
  return (
    <div className='insured-form'>
      <div className='scrollable-div scrollbar-light-theme'>
        <EmployeeDetails />
        <InsuredDetails />
        <PropertyDetails />
      </div>
    </div>
  );
};
