import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

type InsuredContextValue = {
  isInsuredFormOpen: boolean;
  openInsuredForm: () => void;
  closeInsuredForm: () => void;
  toggleInsuredForm: () => void;
};

const initialContext: InsuredContextValue = {
  isInsuredFormOpen: false,
  openInsuredForm: (): void => undefined,
  closeInsuredForm: (): void => undefined,
  toggleInsuredForm: (): void => undefined,
};

export const InsuredConfigContext = createContext(initialContext);

export const InsuredConfigProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [isInsuredFormOpen, setIsInsuredFormOpen] = useState(false);

  const openInsuredForm = useCallback(() => {
    setIsInsuredFormOpen(true);
  }, []);

  const closeInsuredForm = useCallback(() => {
    setIsInsuredFormOpen(false);
  }, []);

  const toggleInsuredForm = useCallback(() => {
    setIsInsuredFormOpen((prev) => !prev);
  }, []);

  const value = useMemo(
    () => ({
      isInsuredFormOpen,
      openInsuredForm,
      closeInsuredForm,
      toggleInsuredForm,
    }),
    [isInsuredFormOpen, openInsuredForm, closeInsuredForm, toggleInsuredForm]
  );

  return <InsuredConfigContext.Provider value={value}>{children}</InsuredConfigContext.Provider>;
};
