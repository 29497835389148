import { BannerNotificationType, Button, TextField } from '@yembo/yemblocks-core';
import { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import { useRequestResetPassword } from 'src/queries/employee';
import { isValidEmail } from 'src/util/validate_data';
import { SectionHeader } from '../SectionHeader';
import { useSubmitOnEnterKey } from '../_util/useEnterKeySubmit';

type Props = {
  setAlert: React.Dispatch<SetStateAction<{ type: BannerNotificationType; text: string } | null>>;
};

export const ForgotPassword = ({ setAlert }: Props): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [isValid, setIsValid] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const { mutateAsync: requestResetPassword, isLoading } = useRequestResetPassword();

  useEffect(() => {
    if (isDisabled) {
      const timeoutId = setTimeout(() => {
        setIsDisabled(false);
      }, 7000);

      return () => clearTimeout(timeoutId);
    }
  }, [isDisabled]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);

    if (!isValid) setIsValid(true);
  };

  const emailField = (
    <TextField name='email' label='Email' onChange={onChange} errorMessage='Invalid email' isValid={isValid} />
  );

  const onSubmit = async () => {
    if (!isValidEmail(email)) {
      setIsValid(false);
      return;
    }
    setIsDisabled(true);
    const { status } = await requestResetPassword({ email });

    if (status[0].message) setAlert({ type: 'success', text: status[0].message });
  };

  useSubmitOnEnterKey({ onSubmit });

  return (
    <div className='forgot-password'>
      <SectionHeader
        heading='Reset Password'
        caption='Enter your email and we will send you a link to reset your password'
      />
      {emailField}
      <Button
        text='Submit'
        className='yb-primary-button'
        onClick={onSubmit}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <Button
        text='Back'
        className='yb-tertiary-button'
        size='small'
        onClick={() => history.back()}
        isDisabled={isLoading}
      />
    </div>
  );
};
