import { generateKey } from 'src/util/generateKey';
import { FormDataWithCompany } from '../InsuredPopup';

export type SampleSurveyData = {
  key: string;
  companyKey: string;
  insuredKey: string;
  givenName: string;
  familyName: string;
  email?: string;
  phone?: string;
};

export const getSampleSurveyData = (formData: FormDataWithCompany): SampleSurveyData => {
  const { company, insured } = formData;
  const { givenName, familyName, email, phone } = insured;

  return {
    key: generateKey('survey'),
    companyKey: company.key,
    insuredKey: generateKey('insured'),
    givenName,
    familyName,
    email,
    phone,
  };
};
