import { LoadingSpinner, MoverAppScreen, TopNavbar } from '@yembo/yemblocks-core';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { InsuredConfigProvider } from './CreateInsured/_contexts/InsuredConfigProvider';
import { ErrorPage } from './ErrorPage';
import { Sidebar } from './Sidebar';

const Insured = lazy(() => import('./Insured'));
const Admin = lazy(() => import('./Admin'));
const Annotate = lazy(() => import('./Annotate'));
const Settings = lazy(() => import('./Settings'));
const Reporting = lazy(() => import('./Reporting'));
const AIDashboards = lazy(() => import('./AIDashboards'));

export const Kepler = (): JSX.Element => {
  const loadingFallback = (
    <MoverAppScreen
      mainHeader='Loading ...'
      topNav={
        <div className='placeholder-breads'>
          <TopNavbar items={[{ id: 'temp', text: '' }]} />
        </div>
      }
      isOffline={false}
    >
      <div className='spinner-wrapper-centred-style'>
        <LoadingSpinner name='blue-spinner' className='kepler-spinner' />
      </div>
    </MoverAppScreen>
  );

  return (
    <InsuredConfigProvider>
      <div className='kepler-main-container'>
        <Sidebar />
        <Suspense fallback={loadingFallback}>
          <Routes>
            <Route path='insured/*' element={<Insured />} />
            <Route path='annotate/*' element={<Annotate />} />
            <Route path='admin/*' element={<Admin />} />
            <Route path='settings/*' element={<Settings />} />
            <Route path='reporting/*' element={<Reporting />} />
            <Route path='ai-dashboards/*' element={<AIDashboards />} />

            <Route path='error'>
              <Route path=':errorCode/*' element={<ErrorPage />} />
              <Route index element={<ErrorPage />} />
            </Route>

            <Route path='*' element={<Navigate to='/insured/needs-media' />} />
            <Route index element={<Navigate to='/insured/needs-media' />} />
          </Routes>
        </Suspense>
      </div>
    </InsuredConfigProvider>
  );
};
