import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useQueryCompanies } from 'src/queries/companies';
import { useQueryAuthUser } from 'src/queries/employee';
import { Company, Insured, Property } from 'src/types';
import { isEmptyStringOrWhitespace } from 'src/util/isEmptyStringOrWhitespace';
import { getDefaultCountry, isValidEmail } from 'src/util/validate_data';
import { InsuredConfigContext } from './InsuredConfigProvider';

export type InsuredForm = Pick<Insured, 'id' | 'key' | 'givenName' | 'familyName' | 'phone' | 'email'>;
export type PropertyForm = Pick<
  Property,
  'key' | 'country' | 'streetAddress' | 'addressLine2' | 'city' | 'state' | 'zip'
>;

export type FormData = {
  company?: Company;
  insured: InsuredForm;
  property: PropertyForm;
};

export type State = {
  isSuperAdmin: boolean;
  isPhoneValid: boolean;
  isFormValid: boolean;
  duplicateInsuredKey?: string;
  insured?: Insured;
};

export type InsuredContextValue = {
  insuredState: State;
  insuredFormData: FormData;
  clearFormData: () => void;
  dispatchInsuredState: Dispatch<Partial<State>>;
  dispatchInsuredFormData: Dispatch<Partial<FormData>>;
};

export const initialFormData: FormData = {
  company: undefined,
  insured: {
    givenName: '',
    familyName: '',
    phone: '',
    email: '',
    id: '',
    key: '',
  },
  property: {
    streetAddress: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: 'us',
    key: '',
  },
};

const initialState: State = {
  isSuperAdmin: false,
  isPhoneValid: false,
  isFormValid: false,
};

const initialContext: InsuredContextValue = {
  insuredState: initialState,
  insuredFormData: initialFormData,
  clearFormData: () => undefined,
  dispatchInsuredState: () => () => undefined,
  dispatchInsuredFormData: () => () => undefined,
};

export const InsuredContext = createContext(initialContext);

const stateReducer = (state: State, data: Partial<State>) => ({ ...state, ...data });
const formDataReducer = (state: FormData, data: Partial<FormData>) => ({ ...state, ...data });

export const checkIsNameValid = (value: string): boolean => {
  return value.trim().length > 0;
};

export const InsuredProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { isInsuredFormOpen } = useContext(InsuredConfigContext);
  const { data: companies, isLoading: isCompanyListLoading } = useQueryCompanies({ enabled: isInsuredFormOpen });
  const { data: authUser, isLoading: isAuthLoading } = useQueryAuthUser();
  const activeRole = authUser?.impersonatedRole ?? authUser?.role;

  const [insuredState, dispatchInsuredState] = useReducer(stateReducer, initialState);
  const [formData, dispatchInsuredFormData] = useReducer(formDataReducer, initialFormData);
  const formDataRef = useRef(formData);
  formDataRef.current = formData;

  const { isSuperAdmin, isPhoneValid } = insuredState;
  const {
    company,
    insured: { email, phone, givenName, familyName },
    property: { country, streetAddress, state, city, zip },
  } = formData;

  // Set isSuperAdmin
  useEffect(() => {
    if (isAuthLoading) return;
    dispatchInsuredState({
      isSuperAdmin: activeRole === 'superadmin',
    });
  }, [isAuthLoading, activeRole]);

  // // Set initial company
  useEffect(() => {
    if (isCompanyListLoading || isAuthLoading) return;

    const company =
      companies &&
      (isSuperAdmin ? companies.find((company) => company.name === 'Yembo') ?? companies[0] : companies[0]);

    if (company) {
      dispatchInsuredFormData({
        company,
        property: {
          ...formDataRef.current.property,
          country: getDefaultCountry({ company, companies: companies ?? [] }) as string,
        },
      });
    }
  }, [companies, isCompanyListLoading, isAuthLoading, isSuperAdmin]);

  // Check validation
  useEffect(() => {
    const isPhoneAndChecked = phone?.length !== 0 && isPhoneValid;
    const isEmailValid = email?.length !== 0 && isValidEmail(email);
    const isAddressValid = Boolean(country && streetAddress && state && city && zip);

    const isFormValid =
      isAddressValid &&
      Boolean(company) &&
      !isEmptyStringOrWhitespace(givenName) &&
      checkIsNameValid(givenName) &&
      checkIsNameValid(familyName) &&
      !isEmptyStringOrWhitespace(familyName) &&
      ((isPhoneAndChecked && (email?.length === 0 || isValidEmail(email))) ||
        (isEmailValid && (phone?.length === 0 || isPhoneAndChecked)));

    dispatchInsuredState({ isFormValid });
  }, [company, email, phone, givenName, familyName, isPhoneValid, country, streetAddress, state, city, zip]);

  const clearFormData = useCallback(() => {
    dispatchInsuredFormData(initialFormData);
  }, []);

  const value = useMemo(
    () => ({
      insuredState,
      insuredFormData: formData,
      clearFormData,
      dispatchInsuredState,
      dispatchInsuredFormData,
    }),
    [insuredState, formData, clearFormData]
  );

  return <InsuredContext.Provider value={value}>{children}</InsuredContext.Provider>;
};
