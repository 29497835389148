import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import * as Messenger from 'src/react/_sw/Messanger';
import { installPolyfill } from 'src/util/env';
import { initializeIntercom } from 'src/util/intercom';
import { snapshotDebugInfo } from './analytics/_util/snapshotDebugInfo';
import { queryClient } from './queries';
import { Kepler } from './react/Kepler';
import { KeplerEditorShell } from './react/KeplerEditor/KeplerEditorShell';
import { LoginMfa } from './react/LoginMfa';
import { AuthUserProvider } from './react/_components/AuthUserProvider';
import { MobileWarningModal } from './react/_components/MobileWarningModal';
import { NotificationsProvider } from './react/_components/Notifications';
import * as serviceWorkerRegistration from './react/_sw/serviceWorkerRegistration';
import { UpdateChecker } from './react/_sw/UpdateChecker';
import './style/_import.scss';
import reportWebVitals from './test/reportWebVitals';
import { fixMobileViewportUnits } from './util/fixMobileViewportUnits';
import { PrivateRoute } from './util/privateRoute';
import { StrictMode } from 'react';

const reactQueryDebuggerActive = process.env.REACT_APP_QUERY_DEBUGGER === 'true';
installPolyfill();
initializeIntercom();

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthUserProvider>
        <NotificationsProvider>
          <BrowserRouter>
            <MobileWarningModal />
            <Routes>
              <Route path='/login' element={<LoginMfa />} />
              <Route path='/verify-mfa' element={<LoginMfa />} />
              <Route path='/verify-backup-code' element={<LoginMfa />} />
              <Route path='/resend-mfa' element={<LoginMfa />} />
              <Route path='/forgot-password' element={<LoginMfa />} />
              <Route path='/reset-password/:email/:token' element={<LoginMfa />} />
              <Route path='/reset-expired-password/:email/:token' element={<LoginMfa />} />
              <Route path='/activate-account/:email/:token/:activationToken' element={<LoginMfa />} />
              <Route path='/' element={<PrivateRoute />}>
                <Route path='reset-password' element={<LoginMfa />} />
                <Route path='demo' element={<KeplerEditorShell />} />
                <Route
                  path='insured/:insuredKey/:projectKey/view-media/:regionKey/3D-view'
                  element={<KeplerEditorShell />}
                />
                <Route path='annotate/:regionKey/3D-view' element={<KeplerEditorShell />} />
                <Route index element={<Navigate to='/insured/needs-media' />} />
                <Route path='*' element={<Kepler />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </NotificationsProvider>
        {reactQueryDebuggerActive && <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />}
        <UpdateChecker />
      </AuthUserProvider>
    </QueryClientProvider>
  </StrictMode>
);

serviceWorkerRegistration.register({
  onUpdate: () => {
    Messenger.sendMessage('update_ready', null);
  },
});

snapshotDebugInfo();
fixMobileViewportUnits();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
