import { Button, Illustration } from '@yembo/yemblocks-core';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useNavigate } from 'react-router';

import { InsuredConfigContext } from '../_contexts/InsuredConfigProvider';
import { InsuredContext } from '../_contexts/InsuredProvider';

import { getFullName } from 'src/react/_util';

interface Props {
  onCreateNewInsured: () => void;
  duplicateInsuredKey: string;
  isLoading?: boolean;
}

export const DuplicateMessage = ({ onCreateNewInsured, duplicateInsuredKey, isLoading }: Props): JSX.Element => {
  const { insuredState, dispatchInsuredState, clearFormData } = useContext(InsuredContext);
  const { closeInsuredForm } = useContext(InsuredConfigContext);

  const { insured } = insuredState;

  if (!insured) {
    throw new Error('No duplicate survey found');
  }
  const navigate = useNavigate();

  const navigateToInsured = () => {
    navigate(`/insured/${duplicateInsuredKey}/overview`);
    closeInsuredForm();
    clearFormData();
    dispatchInsuredState({
      duplicateInsuredKey: undefined,
    });
  };

  return (
    <div className='duplicate-message'>
      <div>Potential duplicate found!</div>
      <Illustration className='dedupe' type='dedupe' />
      <div className='message'>{`There is already a record for ${getFullName(insured)} created:`}</div>
      <div className='message'>{`${dayjs(insured.createdAt).format('LLLL')}`}</div>
      <Button className='yb-primary-button' text='Go to Record' onClick={navigateToInsured} />
      <Button
        className='yb-tertiary-button'
        text='Create New Insured'
        onClick={onCreateNewInsured}
        isLoading={isLoading}
      />
    </div>
  );
};
