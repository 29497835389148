import { useEffect } from 'react';

interface Props {
  onSubmit: () => void;
}

export const useSubmitOnEnterKey = ({ onSubmit }: Props): void => {
  useEffect(() => {
    const submitOnEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') onSubmit();
    };

    document.addEventListener('keyup', submitOnEnter);

    return () => {
      document.removeEventListener('keyup', submitOnEnter);
    };
  }, [onSubmit]);
};
