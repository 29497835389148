import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Employee } from '../types';
import {
  AUTH_USER_MUTATION,
  AUTH_USER_QUERY,
  COMPANIES_QUERY,
  EMPLOYEE_LIST_QUERY,
  EMPLOYEE_MUTATION,
  FORCE_RESET_PASSWORD,
  GENERATE_BACKUP_CODES,
  INSUREDS_QUERY_INFINITE,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
} from './queryKeys';
import { invalidateIfAllMutationsDone, queryClient, yemboApiCall, yemboApiCallWithReturnValue } from './index';
import { EmployeeWithAllFields } from 'src/react/Kepler/Settings';
import { DeleteResponse } from './specialty-items';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';
import { useContext } from 'react';

type MutationResult<A> = UseMutationResult<void, unknown, A, { previous?: Employee[] }>;
type EmployeeKeyAndPassword = Pick<EmployeeWithAllFields, 'key' | 'password'>;
type EmployeeWithKeyAndConfirmPassword = Pick<Employee, 'key'> & Partial<Employee> & { confirmPassword?: string };
type EmployeeWithRequiredFields = Pick<Employee, 'givenName' | 'familyName' | 'email' | 'companyKeys'> &
  Partial<Employee>;

export type EmployeeResponse = {
  status: {
    message?: string | undefined;
    type?: string;
  }[];
  employee: Employee;
};
export class EmployeeMutationError extends Error {
  constructor(message: string, readonly type: DeleteResponse['status'][0]['type'], readonly fields?: string[]) {
    super(message);
  }
}
const url = 'employee';

const requestAuthUser = async (): Promise<Employee> => {
  const employee = await yemboApiCallWithReturnValue<Employee, string>({
    key: 'employee',
    url,
  });

  if (!employee) throw new Error('Employee not found');

  return employee;
};

const setImpersonation = (authUser: Employee) => {
  const { impersonatedRole, impersonatedCompanyKeys } = authUser;

  localStorage.setItem('impersonatedCompanyKeys', (impersonatedCompanyKeys ?? '').toString());
  localStorage.setItem('impersonatedRole', impersonatedRole ?? '');
};

export const useQueryAuthUser = (): UseQueryResult<Employee, Error> =>
  useQuery({
    queryKey: [AUTH_USER_QUERY],
    queryFn: () => requestAuthUser(),
    onSuccess: (authUser) => setImpersonation(authUser),
  });

type AvatarCredentials = Record<string, { formInputs: Record<string, string> }>;

export const getS3CredentialsForAvatar = async (): Promise<AvatarCredentials | null> =>
  await yemboApiCallWithReturnValue({
    key: 'urls',
    method: 'PUT',
    url: `employee/request-avatar-urls`,
  });

export type UpdateResponse = {
  status: {
    message?: string | undefined;
    type?: string;
    fields?: string[];
  }[];
};

const updateAuthUser = async (data: Partial<Employee>) => {
  const response = await yemboApiCall({
    url: 'employee',
    method: 'PUT',
    body: JSON.stringify(data),
  });

  const body = (await response.json()) as UpdateResponse;

  if (!response.ok) {
    const { message = 'Failed to update employee profile!' } = body.status[0];
    throw new Error(message);
  }

  return data;
};

export const useUpdateAuthUser = (): UseMutationResult<
  Partial<Employee>,
  Error,
  Partial<EmployeeWithAllFields>,
  { previous: EmployeeWithAllFields | undefined }
> => {
  const queryKey = [AUTH_USER_QUERY];
  const { setAuthUser } = useContext(AuthUserContext);

  return useMutation({
    mutationKey: [AUTH_USER_MUTATION],
    mutationFn: async (data) => await updateAuthUser(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries(queryKey);
      const authUser = queryClient.getQueryData<EmployeeWithAllFields>(queryKey);

      queryClient.setQueryData(queryKey, { ...authUser, ...data });
      setAuthUser((prev) => (prev ? { ...prev, ...data } : prev));

      return { previous: authUser };
    },
    onSuccess: (data: Partial<Employee>) => {
      const authUser = queryClient.getQueryData<EmployeeWithAllFields>(queryKey);

      if (authUser) {
        const { password, oldPassword } = authUser;
        const { photo } = data;

        if (photo) {
          queryClient.invalidateQueries(queryKey);
        }

        if (password || oldPassword) {
          delete authUser.password;
          delete authUser.oldPassword;
          queryClient.setQueryData(queryKey, authUser);
        }

        if (data.impersonatedRole || data.impersonatedCompanyKeys) {
          setImpersonation(authUser);
          queryClient.invalidateQueries([EMPLOYEE_LIST_QUERY]);
          queryClient.invalidateQueries([COMPANIES_QUERY]);
          queryClient.invalidateQueries([INSUREDS_QUERY_INFINITE]);
        }
      }
    },
    onError: (_, __, context) => {
      if (context) {
        queryClient.setQueryData(queryKey, context.previous);
        setAuthUser(context.previous);
      }
    },
  });
};

const requestEmployeeList = async (companyKeys?: string[] | null): Promise<Employee[]> => {
  const hasCompanyKeysFilter = companyKeys && companyKeys.length > 0;

  const url = hasCompanyKeysFilter ? 'employee/list' : 'employee/list-all';
  const urlParams = hasCompanyKeysFilter
    ? new URLSearchParams({
        companyKeys: JSON.stringify(companyKeys),
      })
    : undefined;

  return (
    (await yemboApiCallWithReturnValue({
      url,
      urlParams,
      key: 'employees',
    })) ?? []
  );
};

export const useQueryEmployeeList = (companyKeys?: string[] | null): UseQueryResult<Employee[], Error> =>
  useQuery({
    queryKey: [EMPLOYEE_LIST_QUERY, companyKeys],
    queryFn: () => {
      return requestEmployeeList(companyKeys);
    },
  });

type BackupCodeResponse = {
  key: string;
  backupCodes: string[];
  backupCodesGeneratedAt: string;
} & UpdateResponse;

const requestBackupCodes = async (data: { key: string }) => {
  const response = await yemboApiCall({
    url: 'employee/backup-codes',
    method: 'PUT',
    body: JSON.stringify(data),
  });

  const body = (await response.json()) as BackupCodeResponse;

  if (!response.ok) {
    const { message = 'Failed to generate backup codes!' } = body.status[0];
    throw new Error(message);
  }

  return body;
};

export const useGenerateBackupCodes = (): UseMutationResult<BackupCodeResponse, Error, { key: string }, unknown> => {
  const queryKey = [AUTH_USER_QUERY];

  return useMutation({
    mutationKey: [GENERATE_BACKUP_CODES],
    mutationFn: async (data) => await requestBackupCodes(data),
    onSuccess: (data: BackupCodeResponse) => {
      const authUser = queryClient.getQueryData<EmployeeWithAllFields>(queryKey);

      if (authUser) {
        authUser.backupCodesGeneratedAt = data.backupCodesGeneratedAt;
        queryClient.setQueryData(queryKey, authUser);
      }
    },
  });
};

export const requestResetPassword = async (data: { email: string }): Promise<UpdateResponse> => {
  const response = await yemboApiCall({
    method: 'PUT',
    url: 'employee/request-password-reset',
    body: JSON.stringify(data),
  });

  const body = (await response.json()) as UpdateResponse;

  if (!response.ok) {
    const { message = 'Failed to initiate password reset!' } = body.status[0];
    throw new Error(message);
  }

  return body;
};

export const useRequestResetPassword = (): UseMutationResult<UpdateResponse, Error, { email: string }, unknown> => {
  return useMutation({
    mutationKey: [REQUEST_RESET_PASSWORD],
    mutationFn: async (data) => await requestResetPassword(data),
  });
};

type ResetPassword = {
  password: string;
  passwordResetLink?: string;
};

export const resetPassword = async (data: ResetPassword): Promise<UpdateResponse> => {
  const response = await yemboApiCall({
    method: 'PUT',
    url: 'employee/reset-password',
    body: JSON.stringify(data),
  });

  const body = (await response.json()) as UpdateResponse;

  if (!response.ok) {
    const { message = 'Failed to reset password!', type, fields } = body.status[0];
    throw new EmployeeMutationError(message, type, fields);
  }

  return body;
};

export const useResetPassword = (): UseMutationResult<
  UpdateResponse,
  EmployeeMutationError,
  ResetPassword,
  unknown
> => {
  return useMutation({
    mutationKey: [RESET_PASSWORD],
    mutationFn: async (data) => await resetPassword(data),
  });
};

export const forceResetPassword = async (data: EmployeeKeyAndPassword): Promise<UpdateResponse> => {
  const response = await yemboApiCall({
    method: 'PUT',
    url: 'employee/force-password-reset',
    body: JSON.stringify(data),
  });

  const body = (await response.json()) as UpdateResponse;

  if (!response.ok) {
    const { message = 'Failed to reset password!', type } = body.status[0];
    throw new EmployeeMutationError(message, type);
  }

  return body;
};

export const useForceResetPassword = (): UseMutationResult<UpdateResponse, Error, EmployeeKeyAndPassword, unknown> => {
  return useMutation({
    mutationKey: [FORCE_RESET_PASSWORD],
    mutationFn: async (data) => await forceResetPassword(data),
  });
};

const createEmployee = async (data: EmployeeWithRequiredFields): Promise<EmployeeResponse> => {
  const response = await yemboApiCall({
    url,
    method: 'POST',
    body: JSON.stringify(data),
  });

  const body = (await response.json()) as EmployeeResponse;

  if (!response.ok) {
    const { message = 'Failed to create employee!', type } = body.status[0];
    throw new EmployeeMutationError(message, type);
  }

  return body;
};

const updateEmployee = async (employee: EmployeeWithKeyAndConfirmPassword): Promise<void> => {
  const response = await yemboApiCall({
    url,
    method: 'PUT',
    body: JSON.stringify({ ...employee }),
  });

  const body = (await response.json()) as DeleteResponse;

  if (!response.ok) {
    const { message = 'Failed to update employee!', type } = body.status[0];
    throw new EmployeeMutationError(message, type);
  }
};

const deleteEmployee = async (employee: EmployeeKeyAndPassword): Promise<void> => {
  const response = await yemboApiCall({
    url,
    method: 'DELETE',
    body: JSON.stringify({ ...employee }),
  });

  const body = (await response.json()) as DeleteResponse;

  if (!response.ok) {
    const { message = 'Failed to delete employee!', type } = body.status[0];
    throw new EmployeeMutationError(message, type);
  }
};

function useEmployeeMutation<A extends Partial<Employee>>(
  mutate: (employee: A) => Promise<void>,
  updateOptimistic: (employee: A, previous: Employee[]) => Employee[],
  onError?: (error: unknown) => void
): MutationResult<A> {
  const queryClient = useQueryClient();
  const queryKey = [EMPLOYEE_LIST_QUERY];
  const mutationKey = [EMPLOYEE_MUTATION];

  return useMutation({
    mutationKey,
    mutationFn: async (employee) => await mutate(employee),
    onMutate: async (employee) => {
      await queryClient.cancelQueries(queryKey);

      const previous = queryClient.getQueryData<Employee[]>(queryKey);

      if (previous) {
        queryClient.setQueryData<Employee[]>(queryKey, updateOptimistic(employee, previous));
      }

      return { previous };
    },
    onError: (error, _, context) => {
      onError?.(error);
      if (context?.previous) {
        queryClient.setQueryData<Employee[]>([EMPLOYEE_LIST_QUERY], context.previous);
      }
    },
    onSettled: () => invalidateIfAllMutationsDone(queryClient, queryKey, mutationKey),
  });
}

export const useCreateEmployee = (): UseMutationResult<
  EmployeeResponse,
  EmployeeMutationError,
  EmployeeWithRequiredFields,
  { previous?: Employee[] }
> => {
  const queryKey = [EMPLOYEE_LIST_QUERY];
  const mutationKey = [EMPLOYEE_MUTATION];

  return useMutation({
    mutationKey,
    mutationFn: async (data) => await createEmployee(data),

    onError: (_, __, context) => {
      if (context?.previous) {
        queryClient.setQueryData<Employee[]>(queryKey, context.previous);
      }
    },
    onSettled: () => invalidateIfAllMutationsDone(queryClient, queryKey, mutationKey),
  });
};

export const useUpdateEmployee = (
  onError?: (error: unknown) => void
): MutationResult<EmployeeWithKeyAndConfirmPassword> =>
  useEmployeeMutation(
    updateEmployee,
    (employee, previous) =>
      previous?.map((previousEmployee) =>
        previousEmployee.key === employee.key ? { ...previousEmployee, ...employee } : previousEmployee
      ),
    onError
  );

export const useDeleteEmployee = (onError?: (error: unknown) => void): MutationResult<EmployeeKeyAndPassword> =>
  useEmployeeMutation(
    deleteEmployee,
    (employee, previous) => previous.filter((previousEmployee) => previousEmployee.key !== employee.key),
    onError
  );
