import { getBaseEnvironments } from './getBaseEnvironments';

/**
 * geo:              Logical heading to display to the user to group by. Unused now but could be used to group countries
 *                   if we add more and the list gets unwieldy.
 * code:             ISO 3166 country code if the region maps to a country, other unique 2-char code for the regions.
 *                   This code will be used as top level domain for URL, if topLevelDomain is not provided.
 * country:          Country/region name to display in the UI
 * flagUrl:          Link to flag SVG icon
 * topLevelDomain:   Domain's suffix, i.e. "ai" in yembo.ai https://doepud.co.uk/blog/anatomy-of-a-url
 * icon:             Name of the svg flag.
 * environments:     Environments available for the region.
 */

export const allRegions = [
  {
    country: 'United States',
    geo: 'North America',
    code: 'US',
    topLevelDomain: 'ai',
  },
  {
    geo: 'North America',
    code: 'NA',
    country: 'Rest of North America',
    icon: 'north-america',
    topLevelDomain: 'ai',
  },
  {
    country: 'Albania',
    geo: 'Europe',
    code: 'AL',
  },
  {
    country: 'Austria',
    geo: 'Europe',
    code: 'AT',
  },
  {
    country: 'Czech Republic',
    geo: 'Europe',
    code: 'CZ',
  },
  {
    country: 'France',
    geo: 'Europe',
    code: 'FR',
  },
  {
    country: 'Germany',
    geo: 'Europe',
    code: 'DE',
  },
  {
    country: 'Italy',
    geo: 'Europe',
    code: 'IT',
  },
  {
    country: 'Luxembourg',
    geo: 'Europe',
    code: 'LU',
  },
  {
    country: 'Netherlands',
    geo: 'Europe',
    code: 'NL',
    topLevelDomain: 'co.nl',
  },
  {
    country: 'Poland',
    geo: 'Europe',
    code: 'PL',
  },
  {
    country: 'Portugal',
    geo: 'Europe',
    code: 'PT',
  },
  {
    country: 'Spain',
    geo: 'Europe',
    code: 'ES',
  },
  {
    country: 'Switzerland',
    geo: 'Europe',
    code: 'CH',
  },
  {
    country: 'United Kingdom',
    geo: 'Europe',
    code: 'UK',
    topLevelDomain: 'co.uk',
  },
  {
    country: 'Rest of Europe',
    geo: 'Europe',
    code: 'EU',
  },
  {
    country: 'China',
    geo: 'Asia',
    code: 'CN',
  },
  {
    country: 'Hong Kong',
    geo: 'Asia',
    code: 'HK',
  },
  {
    country: 'India',
    geo: 'Asia',
    code: 'IN',
  },
  {
    country: 'Japan',
    geo: 'Asia',
    code: 'JP',
  },
  {
    country: 'Russia',
    geo: 'Asia',
    code: 'RU',
  },
  {
    country: 'Singapore',
    geo: 'Asia',
    code: 'SG',
    topLevelDomain: 'com.sg',
  },
  {
    country: 'South Korea',
    geo: 'Asia',
    code: 'KR',
  },
  {
    country: 'Taiwan',
    geo: 'Asia',
    code: 'TW',
  },
  {
    country: 'United Arab Emirates',
    geo: 'Asia',
    code: 'AE',
  },
  {
    country: 'Rest of Asia',
    geo: 'Asia',
    code: 'AS',
    icon: 'asia',
    topLevelDomain: 'com.sg',
  },
  {
    country: 'New Zealand',
    geo: 'Oceania',
    code: 'NZ',
    topLevelDomain: 'co.nz',
  },
  {
    country: 'Rest of Oceania',
    geo: 'Oceania',
    code: 'AU',
    icon: 'oceania',
    topLevelDomain: 'com.sg',
  },
].map((region) => {
  const { icon, code } = region;

  const lowercaseCode = code.toLowerCase();
  const flagUrl = `https://media.yembo.ai/i18n/flags/${icon || lowercaseCode}.svg`;
  const topLevelDomain = region.topLevelDomain || lowercaseCode;
  const baseEnvironments = getBaseEnvironments(region);
  const canView = baseEnvironments.some((baseEnvironment) =>
    (process.env.REACT_APP_API_ENV ?? 'dev').startsWith(baseEnvironment)
  ); // we use `startsWith` instead of `===` to support `prod-eu`, etc.

  return {
    ...region,
    topLevelDomain,
    lowercaseCode,
    flagUrl,
    canView,
  };
});
