import { Button, CountryDropdown, TextField, TextFieldPassword } from '@yembo/yemblocks-core';
import { determine } from 'jstz';
import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { allRegions } from 'src/react/LoginMfa/LoginForm/_util/allRegions';
import { isValidEmail } from 'src/util/validate_data';
import { LoginFormData } from '../';
import { login, LoginError, readAuthUser } from 'src/react/_components/AuthUserProvider/api/auth';
import { SectionHeader } from '../SectionHeader';
import { useSubmitOnEnterKey } from '../_util/useEnterKeySubmit';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';

interface Props {
  formData: LoginFormData;
  setFormData: Dispatch<SetStateAction<LoginFormData>>;
}

export const LockedLoginMessage = () => (
  <p>
    You have been locked out of your account due to too many failed login attempts.{' '}
    <a href='mailto:insurance.support@yembo.ai'>Contact support</a> to unlock your account.
  </p>
);

export const LoginForm = ({ formData, setFormData }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { setAuthUser } = useContext(AuthUserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false); // local loading
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | JSX.Element>();
  const [locked, setLocked] = useState(false);

  const onClickLogin = useCallback(() => {
    if (!isValidEmail(formData.email)) {
      setIsError(true);
      return;
    }

    const timezone = determine().name() || 'UTC';

    const authWithLoading = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const accessToken = await login(formData, timezone, navigate, setAuthUser);
        if (accessToken) {
          await readAuthUser(setAuthUser);
        }
      } catch (error) {
        const { message, passwordResetLink } = error as LoginError;
        const isUserLocked = typeof message === 'string' && message.includes('has been locked');

        if (passwordResetLink) {
          navigate(`/reset-expired-password/${formData.email ?? ''}/${passwordResetLink}`);
        }

        if (isUserLocked) {
          setErrorMessage(<LockedLoginMessage />);
          setFormData({ email: '', password: '' });
          setIsError(false);
        } else {
          setIsError(true);
        }

        setLocked(isUserLocked);
        setIsLoading(false);
      }
    };

    authWithLoading();
  }, [formData, navigate, setAuthUser, setFormData]);

  useSubmitOnEnterKey({ onSubmit: onClickLogin });

  const countryOptions = useMemo(() => {
    return allRegions.filter(({ canView }) => canView).map(({ country, code }) => ({ label: country, value: code }));
  }, []);

  // commenting out for now as we do not have regions enabled here
  // const initialRegion = useMemo(() => {
  //   const apiRegion = process.env.REACT_APP_API_ENV ? process.env.REACT_APP_API_ENV.split('-')[1] : 'us';

  //   return allRegions.find((region) => {
  //     const url = getUrl(region);

  //     const isMatch = isDeveloperMode
  //       ? apiRegion === region.lowercaseCode // When inDeveloperMode = true we can't match by url. Simply match to the region
  //       : url === window.location.host;

  //     return isMatch;
  //     return region.lowercaseCode
  //   });
  // }, []);

  // const onRegionSelect = (option: { label: string; value: string }) => {
  //   const selectedRegion = allRegions.find(({ code }) => code === option.value);
  //   const redirectUrl = `https://${getUrl(selectedRegion)}`;
  //   window.location.replace(redirectUrl);
  // };

  const Location = (
    <div>
      <CountryDropdown onSelect={(e) => e} selectedCountry={'US'} countryOptions={countryOptions} />
      <p className='tiny-text'>Log into the appropriate region by selecting your company's country</p>
    </div>
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((data) => ({ ...data, [name]: value }));

    if (isError) setIsError(false);
  };

  return (
    <div className='login-form content-fade-in'>
      <SectionHeader
        heading='Welcome Back!'
        caption='Please sign in below.'
        showError={isError || !!errorMessage}
        errorMessage={errorMessage ?? 'Invalid email or password'}
      />

      <div className={`fields-container ${locked ? 'locked' : ''}`}>
        {Location}
        <TextField
          disabled={locked}
          name='email'
          value={formData.email}
          label='Email Address'
          onChange={onChange}
          isValid={!isError}
        />
        <TextFieldPassword
          isDisabled={locked}
          name='password'
          defaultValue={formData.password}
          label='Password'
          onChange={onChange}
          isValid={!isError}
        />
        <Button
          text='Forgot password?'
          className='yb-tertiary-button forgot-password-button'
          size='small'
          isDisabled={isLoading || locked}
          onClick={() => navigate('/forgot-password')}
        />
        <Button text='Login' className='yb-primary-button' onClick={onClickLogin} isLoading={isLoading} />
      </div>
    </div>
  );
};
