import { Icon } from '@yembo/yemblocks-core';

type Props = {
  title: string;
  requirements: {
    summary: string;
    isMatched: boolean;
  }[];
};

const RequirementList = ({ title, requirements }: Props): JSX.Element => {
  return (
    <div className='requirements-wrapper'>
      <div className='title'>{title}</div>
      <div className='list'>
        {requirements.map(({ summary, isMatched }) => {
          return (
            <div className='requirement' key={summary}>
              <Icon name='checkmark-circle' className={isMatched ? 'matched' : ''} />
              <span className='summary'>{summary}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RequirementList;
