import { ReactComponent as PhoneSvg } from 'src/assets/svg/iphone.svg';
import { PhoneContent } from './PhoneContent/index';

export type PhoneProps = {
  imageType: 'password' | 'phone' | 'success';
};
export const Phone = ({ imageType }: PhoneProps): JSX.Element => {
  return (
    <div className='phone-wrapper'>
      <PhoneSvg className='phone-svg' />
      <PhoneContent imageType={imageType} />
    </div>
  );
};
