import { Button, CodeInput } from '@yembo/yemblocks-core';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';
import { isNumeric } from 'src/util/validate_data';
import { toInternationalPhoneNumber } from 'src/util/validate_data_get_state';
import { LoginFormData } from '../';
import { verifyMfa } from '../../_components/AuthUserProvider/api/auth';
import { LockedLoginMessage } from '../LoginForm';
import { SectionHeader } from '../SectionHeader';
import { useSubmitOnEnterKey } from '../_util/useEnterKeySubmit';

interface Props {
  formData: LoginFormData;
  setFormData: Dispatch<SetStateAction<LoginFormData>>;
}

export const VerifyMfaForm: FC<Props> = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const { phone, email, preferredMfaChannel } = { ...authUser };
  const [isLoading, setIsLoading] = useState<boolean>(false); // local loading
  const [isValidMfaCode, setIsValidMfaCode] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | JSX.Element>();
  const formattedPhone = phone ? (toInternationalPhoneNumber(phone) as string) : '';
  const resendChannel = sessionStorage.getItem('mfa-resend-channel');
  const channel = resendChannel || preferredMfaChannel;
  const preferredChannelDetail = channel === 'email' ? email : formattedPhone;
  const caption = `We sent a one-time code to: ${preferredChannelDetail || ''}`;
  const [locked, setLocked] = useState(false);

  const resendOtcText = (
    <span className='resend-otc-text'>
      Didn't get the OTC?&nbsp;
      <Button
        text='Resend OTC'
        size='small'
        className='yb-tertiary-button resend-otc-button'
        onClick={() => navigate('/resend-mfa')}
      />
    </span>
  );

  const mfaCodeOnChange = ({ name, code }: { name: string; code: string }) => {
    setFormData((data) => ({ ...data, [name]: code }));
    if (!isValidMfaCode) setIsValidMfaCode(true);
  };

  const onLogin = useCallback(() => {
    if (!isNumeric(formData.mfaCode)) {
      setIsValidMfaCode(false);
      return;
    }
    const verifyWithLoading = async (): Promise<void> => {
      setIsLoading(true);
      formData.backupCode = '';
      try {
        await verifyMfa(formData, navigate, setAuthUser);
      } catch ({ message }) {
        const isUserLocked = typeof message === 'string' && message.includes('has been locked');

        if (isUserLocked) {
          setErrorMessage(<LockedLoginMessage />);
          setFormData({ ...formData, mfaCode: '' });
          setIsError(false);
        } else {
          setIsError(true);
        }

        setLocked(isUserLocked);
        setIsLoading(false);
      }
    };
    verifyWithLoading();
  }, [formData, navigate, setAuthUser, setFormData]);

  useSubmitOnEnterKey({ onSubmit: onLogin });

  return (
    <div className='verify-mfa-form'>
      <SectionHeader
        heading='Verify Account'
        caption={caption}
        showError={isError || !!errorMessage}
        errorMessage={errorMessage ?? 'Please try again with the correct numeric code.'}
      />
      <div className={`fields-container ${locked ? 'locked' : ''}`}>
        <CodeInput name='mfaCode' onChange={mfaCodeOnChange} isValid={isValidMfaCode} />
        {resendOtcText}
        <Button text='Login' className='yb-primary-button login-btn' onClick={onLogin} isLoading={isLoading} />
        <Button
          text='Use backup code'
          className='yb-tertiary-button backup-btn'
          size='small'
          onClick={() => navigate('/verify-backup-code')}
          isDisabled={isLoading}
        />
      </div>
    </div>
  );
};
