import { FC } from 'react';
import { Animation, Button } from '@yembo/yemblocks-core';

export const Error: FC<{ onClickAction: () => void }> = ({ onClickAction }): JSX.Element => {
  return (
    <div className='result-content'>
      <div className='animation-wrapper'>
        <Animation name='contact-failure' loop={false} />
      </div>
      <div className='result-text'>Oops. Something went wrong</div>
      <div className='result-text'>Please try again</div>
      <Button className='yb-primary-button result-main-action' text='Go Back' onClick={onClickAction} />
    </div>
  );
};
