import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Employee } from 'src/types';

export type AuthUserDispatch = Dispatch<SetStateAction<Employee | undefined>>;

export const AuthUserContext = createContext<{
  authUser?: Employee;
  setAuthUser: Dispatch<SetStateAction<Employee | undefined>>;
}>({
  setAuthUser: () => undefined,
});

export function AuthUserProvider({ children }: { children?: ReactNode }) {
  const [authUser, setAuthUser] = useState<Employee>();
  return <AuthUserContext.Provider value={{ authUser, setAuthUser }}>{children}</AuthUserContext.Provider>;
}
