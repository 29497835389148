import { Button, Illustration, MoverAppScreen } from '@yembo/yemblocks-core';
import { useNavigate, useParams } from 'react-router-dom';

export const ErrorPage = (): JSX.Element => {
  const { errorCode } = useParams();
  const navigate = useNavigate();

  const renderErrorPageText = () => {
    switch (errorCode) {
      case '401':
        return (
          <div className={'error-text-wrapper'}>
            <span>Something is not quite right...</span>
            <span>Please try reloading the page</span>
          </div>
        );
      case '403':
        return (
          <div className={'error-text-wrapper'}>
            <span>Oops, looks like you don't have access to this page.</span>
            <span>Double check the url or check with your administrator</span>
          </div>
        );
      case '404':
        return (
          <div className={'error-text-wrapper'}>
            <span>Something is not quite right. This page was not found on our servers</span>
          </div>
        );
      default:
        return (
          <div className={'error-text-wrapper'}>
            <span>Something is not quite right...</span>
          </div>
        );
    }
  };

  const errorPageIllustration = () => {
    switch (errorCode) {
      case '401':
        return 'invalid-link';
      case '403':
        return 'error';
      case '404':
        return 'page-not-found';
      default:
        return 'invalid-link';
    }
  };

  const errorPageButtonText = () => {
    switch (errorCode) {
      case '401':
        return 'Reload Page';
      case '403':
        return 'Go To Insured';
      case '404':
        return 'Go To Insured';
      default:
        return 'Go To Insured';
    }
  };

  const handleErrorPageButtonClick = () => {
    if (errorCode === '401') {
      window.location.reload();
    } else {
      navigate(`/surveys`);
    }
  };

  return (
    <MoverAppScreen mainHeader={errorCode ?? ''} topNav={<></>} isOffline={false}>
      <div className='four-zero-error-page'>
        <Illustration type={errorPageIllustration()} />
        {renderErrorPageText()}
        <Button
          onClick={() => handleErrorPageButtonClick()}
          className={'yb-primary-button'}
          text={errorPageButtonText()}
        />
      </div>
    </MoverAppScreen>
  );
};
