type Props = {
  lineActive: boolean;
};
export const AnimatedLine = ({ lineActive }: Props): JSX.Element => {
  const className = lineActive ? 'active-line' : 'not-active-line';

  return (
    <div className='animated-line-wrap'>
      <span className={`progress-bar-fill ${className} `}></span>
      <svg xmlns='http://www.w3.org/2000/svg' width='5' height='115'>
        <path className='line-svg' d='M0 124.13V.876S1 1 2 1s2-.124 2-.124V124.13S3 124 2 124s-2 .13-2 .13z' />
      </svg>
    </div>
  );
};
