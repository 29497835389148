import { BannerNotification, BannerNotificationType, Illustration, YemboLogo } from '@yembo/yemblocks-core';
import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { readAuthUser } from 'src/react/_components/AuthUserProvider/api/auth';
import { AuthUserContext } from '../_components/AuthUserProvider';
import { AccountActivation } from './AccountActivation';
import { Copyright } from './Copyright';
import { ForgotPassword } from './ForgotPassword';
import { LoginForm } from './LoginForm';
import { ResendMfa } from './ResendMfa';
import ResetPassword from './ResetPassword';
import { VerifyBackupCode } from './VerifyBackupCode';
import { VerifyMfaForm } from './VerifyMfaForm';
import { getPasswordExpirationDays } from './_util/getPasswordExpirationDays';

const PASSWORD_EXPIRATION_DAYS = 7;

export interface LoginFormData {
  email?: string;
  password?: string;
  mfaCode?: string;
  backupCode?: string;
  activationToken?: string;
}

export function LoginMfa(): JSX.Element {
  const location = useLocation();
  const queryClient = useQueryClient();

  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: '',
  });
  const [alert, setAlert] = useState<{ type: BannerNotificationType; text: string } | null>(null);
  const accessToken = localStorage.getItem('accessToken');
  const isLogged = authUser && accessToken !== null;
  const hasEmailAndPassword = formData.email && formData.password;
  const passwordExpirationDays =
    isLogged && authUser.passwordExpiresAt ? getPasswordExpirationDays(authUser.passwordExpiresAt) : undefined;

  useEffect(() => {
    queryClient.resetQueries();
  }, [queryClient]);

  useEffect(() => {
    if (accessToken) readAuthUser(setAuthUser);
  }, [accessToken, setAuthUser]);

  const renderAlert = () =>
    alert && (
      <BannerNotification
        className='alert login-alert'
        type={alert.type}
        text={alert.text}
        onClose={() => setAlert(null)}
      />
    );

  if (location.pathname.startsWith('/activate-account')) {
    return (
      <div>
        <AccountActivation setAlert={setAlert} />
        <Copyright />
        {renderAlert()}
      </div>
    );
  }

  if (isLogged && !location.pathname.startsWith('/reset-password')) {
    if (passwordExpirationDays && passwordExpirationDays < PASSWORD_EXPIRATION_DAYS) {
      return <Navigate to={`/reset-password`} />;
    }

    return <Navigate to='/insured' />;
  }

  let resolvedComponent = <Navigate to='/login' />;

  if (location.pathname.startsWith('/login')) {
    resolvedComponent = <LoginForm formData={formData} setFormData={setFormData} />;
  } else if (location.pathname.startsWith('/reset-expired-password')) {
    resolvedComponent = <ResetPassword isAlreadyExpired />;
  } else if (location.pathname.startsWith('/reset-password')) {
    if (isLogged && passwordExpirationDays && passwordExpirationDays < PASSWORD_EXPIRATION_DAYS) {
      resolvedComponent = <ResetPassword passwordExpiresInDays={passwordExpirationDays} isAlreadyExpired={false} />;
    } else {
      resolvedComponent = <ResetPassword />;
    }
  } else if (location.pathname.startsWith('/forgot-password')) {
    resolvedComponent = <ForgotPassword setAlert={setAlert} />;
  } else if (hasEmailAndPassword) {
    if (location.pathname.startsWith('/verify-mfa')) {
      resolvedComponent = <VerifyMfaForm formData={formData} setFormData={setFormData} />;
    } else if (location.pathname.startsWith('/verify-backup-code')) {
      resolvedComponent = <VerifyBackupCode formData={formData} setFormData={setFormData} />;
    } else if (location.pathname.startsWith('/resend-mfa')) {
      resolvedComponent = <ResendMfa formData={formData} />;
    }
  }

  return (
    <div id='login-new'>
      <div className='container'>
        <YemboLogo />
        <div className='illustration-container'>
          <Illustration type='cat-sleeping' className='illustration' />
        </div>
        {resolvedComponent}
      </div>
      <Copyright />
      {renderAlert()}
    </div>
  );
}
