import { SlidingDrawer } from '@yembo/yemblocks-core';
import { useContext, useState } from 'react';
import { InsuredObject, useCheckDuplicates, useCreateInsured } from 'src/queries/insured';
import { useCreateSampleSurvey } from 'src/queries/survey';
import { Notifications } from 'src/react/_components/Notifications';
import { Company } from 'src/types';
import { generateKey } from 'src/util/generateKey';
import { CreationResult } from '../CreationResult';
import { DuplicateMessage } from '../DuplicateMessage';
import { Form } from '../Form';
import { InsuredConfigContext } from '../_contexts/InsuredConfigProvider';
import { FormData, InsuredContext } from '../_contexts/InsuredProvider';
import { getSampleSurveyData } from '../_util/getSampleSurveyData';

export type FormDataWithCompany = FormData & { company: Company };

export const InsuredPopup = (): JSX.Element | null => {
  const {
    mutateAsync: createSampleSurvey,
    isLoading: isCreateSampleSurveyLoading,
    status: createSurveyStatus,
    reset: resetSurveyStatus,
  } = useCreateSampleSurvey();
  const {
    mutateAsync: createInsured,
    isLoading: isCreateInsuredLoading,
    isSuccess,
    isError,
    isIdle,
    reset: resetInsuredStatus,
  } = useCreateInsured();
  const checkDuplicateMutation = useCheckDuplicates();

  const { isInsuredFormOpen, closeInsuredForm } = useContext(InsuredConfigContext);
  const { insuredState, insuredFormData, dispatchInsuredState, dispatchInsuredFormData, clearFormData } =
    useContext(InsuredContext);
  const { showError } = useContext(Notifications);

  const { isFormValid, isSuperAdmin, duplicateInsuredKey } = insuredState;

  const [createdInsuredKey, setCreatedInsuredKey] = useState('');

  const isLoading = checkDuplicateMutation.isLoading || isCreateInsuredLoading;
  const shouldShowButtons =
    !duplicateInsuredKey &&
    (isLoading || checkDuplicateMutation.isLoading || isIdle) &&
    ['idle', 'loading'].includes(createSurveyStatus);

  const resetFormStateAndQueries = () => {
    if (!isIdle) resetInsuredStatus();
    if (createSurveyStatus !== 'idle') resetSurveyStatus();

    setCreatedInsuredKey('');
    dispatchInsuredState({
      duplicateInsuredKey: undefined,
      insured: undefined,
    });
  };

  const createSampleSurveyHandler = async () => {
    if (!insuredFormData.company) return;

    const insuredFormDataWithCompany = { ...insuredFormData, company: insuredFormData.company };
    const sampleSurveyData = getSampleSurveyData(insuredFormDataWithCompany);

    await createSampleSurvey(sampleSurveyData);

    setCreatedInsuredKey(sampleSurveyData.insuredKey);
    clearFormData();
  };

  const hasInsuredDuplicates = async () => {
    if (!insuredFormData.company) return;

    const { email, phone } = insuredFormData.insured;
    const data = { email, phone, companyKey: insuredFormData.company.key };

    const duplicateResponse = await checkDuplicateMutation.mutateAsync(data);

    if (duplicateResponse && duplicateResponse.hasPotentialDuplicate) {
      dispatchInsuredState({ duplicateInsuredKey: duplicateResponse.insured.key });
      return true;
    }

    if (checkDuplicateMutation.isError) {
      showError('Insured check duplicate error.');
    }

    return false;
  };

  const createInsuredHandler = async () => {
    if (!insuredFormData.company) return;

    const { company, property, insured } = insuredFormData;
    property.key = generateKey('property');
    insured.key = generateKey('insured');
    setCreatedInsuredKey(insured.key);
    dispatchInsuredFormData({
      insured,
      property,
    });
    dispatchInsuredState({
      insured: {
        ...insured,
        companyKey: company.key,
      },
    });

    if (await hasInsuredDuplicates()) return;

    await createNewInsured();
  };

  const createNewInsured = async () => {
    if (!insuredFormData.company) return;

    const {
      insured,
      property,
      company: { key: companyKey },
    } = insuredFormData;

    await createInsured({ companyKey, property, insured } as InsuredObject);
  };

  const renderContent = () => {
    return duplicateInsuredKey ? (
      <DuplicateMessage
        duplicateInsuredKey={duplicateInsuredKey}
        onCreateNewInsured={() => createNewInsured()}
        isLoading={isLoading}
      />
    ) : (
      <Form />
    );
  };

  const renderResult = (): JSX.Element | undefined => {
    const creationResultProps = {
      createdInsuredKey,
      onClickAction: () => resetFormStateAndQueries(),
    };
    if (isError || createSurveyStatus === 'error')
      return <CreationResult {...creationResultProps} resultState='error' />;
    else if (isSuccess) {
      return (
        <CreationResult
          {...creationResultProps}
          resultState='success'
          successProps={{
            resultText: 'Insured has been created.',
            viewButtonText: 'View Insured',
            actionButtonText: 'Add Another',
          }}
        />
      );
    } else if (['success'].includes(createSurveyStatus)) {
      return (
        <CreationResult
          {...creationResultProps}
          resultState='success'
          successProps={{
            resultText: 'Sample Project has been created.',
            viewButtonText: 'View Sample Project',
            actionButtonText: 'Add Another',
          }}
        />
      );
    }
  };

  return (
    <>
      <SlidingDrawer
        className='insured-wrapper'
        header='Create Insured'
        isOpen={isInsuredFormOpen}
        onClose={() => closeInsuredForm()}
        onCloseAnimationEnded={() => {
          if (isSuccess || createSurveyStatus === 'success') {
            resetFormStateAndQueries();
            clearFormData();
          }
        }}
        altActionButtonProps={
          isSuperAdmin && shouldShowButtons
            ? {
                text: 'Generate Sample Project',
                isLoading: isCreateSampleSurveyLoading,
                onClick: createSampleSurveyHandler,
              }
            : undefined
        }
        CTAButtonProps={
          shouldShowButtons
            ? {
                text: 'Create Insured',
                isDisabled: !isFormValid,
                isLoading,
                onClick: createInsuredHandler,
              }
            : undefined
        }
      >
        {isSuccess || isError || ['success', 'error'].includes(createSurveyStatus) ? renderResult() : renderContent()}
      </SlidingDrawer>
    </>
  );
};
