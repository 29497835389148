import * as LogRocket from 'logrocket';
import { isDeveloperMode, isDevEnvironment, environment } from '../util/env';
import { isObject, isString } from '@yembo/yemblocks-core/dist/_util';
import { getSessionData } from 'src/analytics/_util/getSessionData';
import { Employee } from 'src/types';
import { IRequest, IResponse } from 'LR';

export type UserDataLogRocketType = {
  [propName: string]: string | number | boolean;
};

export const isLogRocketEnabled = !isDeveloperMode && !isDevEnvironment;

const getProject = (): string => {
  switch (environment) {
    case 'prod':
      return 'kepler-worker-prod';

    case 'staging':
      return 'kepler-worker-nonprod';

    case 'localhost':
    case 'dev':
    default:
      return '';
  }
};

if (isLogRocketEnabled) {
  const isSensitiveField = (key: string) => {
    const sensitiveFields = ['password', 'access-token', 'accessToken'];
    for (const sensitiveField of sensitiveFields) {
      if (key.toLocaleLowerCase().includes(sensitiveField.toLocaleLowerCase())) {
        return true;
      }
    }
    return false;
  };

  const scrubSensitiveFields = (obj: Record<string, unknown>) => {
    for (const key of Object.keys(obj)) {
      if (isSensitiveField(key)) {
        delete obj[key];
      }

      // Handle nested objects
      const value = obj[key];
      if (isObject(value)) {
        obj[key] = scrubSensitiveFields(value);
      }
    }
    return obj;
  };

  const sanitizeNetworkData = <R extends IRequest | IResponse>(data: R) => {
    // Sanitize headers if present
    if (isObject(data.headers)) {
      for (const header of Object.keys(data.headers)) {
        if (isSensitiveField(header)) {
          delete data.headers[header];
        }
      }
    }

    // Sanitize body if present
    if (data.body && isString(data.body)) {
      try {
        const bodyObject = JSON.parse(data.body) as Record<string, unknown>;
        const parsedBody = scrubSensitiveFields(bodyObject);
        data.body = JSON.stringify(parsedBody);
      } catch (e) {
        // For non-JSON payloads or errors during sanitization, blank out the entire body to be safe
        data.body = undefined;
      }
    }

    return data;
  };

  const project = getProject();

  LogRocket.init(`yembo/${project}`, {
    network: {
      requestSanitizer: (request) => sanitizeNetworkData<IRequest>(request),
      responseSanitizer: (response) => sanitizeNetworkData<IResponse>(response),
    },
  });
}

export const assignIdLogRocket = (authUser: Employee) => {
  if (isLogRocketEnabled) {
    const sessionData: UserDataLogRocketType = getSessionData(authUser);
    LogRocket.identify(authUser.key, sessionData);
  }
};
