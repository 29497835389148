import { location } from './env';

export const generateKey = (
  type:
    | 'scan'
    | 'survey'
    | 'annotation'
    | 'polygon'
    | 'bookmark'
    | 'tag'
    | 'insured'
    | 'project'
    | 'property'
    | 'specialtyItem'
    | 'video'
    | 'image'
    | 'textMessage'
    | 'annotationTask'
): string => {
  const string = `${location}${makeRandomString(32)}`;

  switch (type) {
    case 'scan':
      return `scn${string}`;
    case 'survey':
      return `srv${string}`;
    case 'annotation':
      return `anno${string}`;
    case 'polygon':
      return `poly${string}`;
    case 'bookmark':
      return `book${string}`;
    case 'tag':
      return `tgus${string}`;
    case 'insured':
      return `ins${string}`;
    case 'property':
      return `pr${string}`;
    case 'project':
      return `prj${string}`;
    case 'specialtyItem':
      return `spec${string}`;
    case 'video':
      return `vid${string}`;
    case 'image':
      return `img${string}`;
    case 'textMessage':
      return `tmsg${string}`;
    case 'annotationTask':
      return `annotask${string}`;
    default:
      throw new Error('Unrecognized "type" parameter provided to generateKey()');
  }
};

// Exclude vowels so (offensive) words can't be created by accident
const CHARS = '0123456789BCDFGHJKLMNPQRSTVWXZbcdfghjklmnpqrstvwxz';

export const makeRandomString = (length: number): string => {
  const result = new Array<string>(length);

  for (let i = 0; i < length; i++) {
    const randomChar = CHARS[Math.floor(Math.random() * CHARS.length)];
    result[i] = randomChar;
  }

  return result.join('');
};
