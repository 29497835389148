import { useEffect } from 'react';
import { Employee } from 'src/types';
import { attachIntercomScript } from 'src/util/attachIntercomScript';
import { intercomId, isDeveloperMode, isDevEnvironment } from './env';

let isIntercomEnabled = !isDeveloperMode || !isDevEnvironment;
isIntercomEnabled = true;
export const initializeIntercom = (): void => {
  if (isIntercomEnabled) {
    attachIntercomScript(intercomId);
    window.intercomSettings = {
      app_id: intercomId,
      alignment: 'left',
      action_color: '#04264b',
      background_color: '#04264b',
    };

    const intercom = window.Intercom;
    if (typeof intercom === 'function') {
      intercom('boot', {
        app_id: intercomId,
      });
    }
  }
};

export const setIntercomUser = (authUser: Employee): void => {
  isIntercomEnabled &&
    window.Intercom &&
    window.Intercom('update', {
      user_id: authUser.key,
      email: authUser.email,
      name: `${authUser.givenName} ${authUser.familyName}`,
    });
};

declare global {
  interface Window {
    Intercom: unknown;
  }
}

export function useHideIntercom(): void {
  useEffect(() => {
    const intercom = window.Intercom;
    if (typeof intercom === 'function') {
      intercom('update', {
        hide_default_launcher: true,
      });
    }

    return () => {
      const intercom = window.Intercom;
      if (typeof intercom === 'function') {
        intercom('update', {
          hide_default_launcher: false,
        });
      }
    };
  }, []);
}
