import { Button, TextFieldPassword } from '@yembo/yemblocks-core';
import { pluralize } from '@yembo/yemblocks-core/dist/_util';
import { determine } from 'jstz';
import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EmployeeMutationError, useResetPassword } from 'src/queries/employee';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';
import { login, readAuthUser } from 'src/react/_components/AuthUserProvider/api/auth';
import { Notifications } from 'src/react/_components/Notifications';
import { SectionHeader } from '../SectionHeader';
import { useSubmitOnEnterKey } from '../_util/useEnterKeySubmit';
import RequirementList from './RequirementList';

export const getPasswordRequirements = ({ password, email }: { password: string; email?: string }) => {
  const isPasswordRequiredLength = password.length >= 8;

  const requirements = [
    {
      summary: 'Contain at least one number (0-9)',
      isMatched: /[0-9]+/.test(password),
    },
    {
      summary: 'Contain at least one lowercase letter (a-z)',
      isMatched: /[a-z]+/.test(password),
    },
    {
      summary: 'Contain at least one uppercase letter (A-Z)',
      isMatched: /[A-Z]+/.test(password),
    },
    {
      summary: 'Contain at least one symbol (!@#$%^&*_-+<>?/)',
      isMatched: /[!@#$%^&*_\-+=<>?]+/.test(password),
    },
    {
      summary: 'Not contain your email',
      isMatched: isPasswordRequiredLength && email ? !password.includes(email) : false,
    },
    {
      summary: 'Be at least 8 characters long',
      isMatched: isPasswordRequiredLength,
    },
  ];

  const isPasswordValid = requirements.every((requirement) => requirement.isMatched);

  return {
    requirements,
    isPasswordValid,
  };
};

type State = {
  password: string;
  confirmPassword: string;
};

interface Props {
  passwordExpiresInDays?: number;
  isAlreadyExpired?: boolean;
}

const ResetPassword = ({ passwordExpiresInDays, isAlreadyExpired }: Props): JSX.Element => {
  const { email, token } = useParams();
  const navigate = useNavigate();
  const { showSuccess, showError } = useContext(Notifications);
  const { authUser, setAuthUser } = useContext(AuthUserContext);

  const userEmail = email ?? authUser?.email;

  const initialState = {
    password: '',
    confirmPassword: '',
  };

  const [state, setState] = useState<State>(initialState);
  const [isLoginInProcess, setIsLoginInProcess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { mutateAsync: resetPassword, isLoading: isResetInProcess } = useResetPassword();

  const { password, confirmPassword } = state;
  const isPasswordsMatched = password && password === confirmPassword;
  const { requirements, isPasswordValid } = getPasswordRequirements({ password, email: userEmail });

  const handleResetError = ({ fields, message }: EmployeeMutationError) => {
    if (fields && fields.includes('oldPasswordRepeated')) {
      setErrorMessage(message);
    } else {
      showError('Ooops. Something went wrong.');
    }
  };

  const loginUser = async () => {
    if (!userEmail) return;

    const timezone = determine().name() || 'UTC';

    setIsLoginInProcess(true);
    try {
      const accessToken = await login(
        { email: decodeURIComponent(userEmail), password },
        timezone,
        navigate,
        setAuthUser
      );
      if (accessToken) await readAuthUser(setAuthUser);
      navigate('/insured');
    } catch {
      showError('Ooops. Something went wrong.');
      navigate('/login');
    }
  };

  const handleResetExpiredPassword = async () => {
    if (!authUser) return;

    const data = {
      password,
    };

    await resetPassword(data, {
      onSuccess: () => {
        showSuccess('Your password has been updated');
        loginUser();
      },
      onError: handleResetError,
    });
  };

  const handleResetPasswordByLink = async () => {
    if (!userEmail || !token) return;

    const data = {
      email: decodeURIComponent(userEmail),
      password,
      passwordResetLink: token,
    };

    await resetPassword(data, {
      onSuccess: () => {
        showSuccess('Your password has been updated');
        loginUser();
      },
      onError: handleResetError,
    });
  };

  const onSubmit = () => (token ? handleResetPasswordByLink() : handleResetExpiredPassword());

  useSubmitOnEnterKey({ onSubmit });

  const renderEmailInputs = () => {
    const inputs = [
      {
        name: 'password',
        label: 'Password',
      },
      {
        name: 'confirmPassword',
        label: 'Confirm Password',
      },
    ];

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      setState((prev) => ({ ...prev, [name]: value }));
    };

    return inputs.map(({ name, label }) => (
      <TextFieldPassword key={name} name={name} label={label} onChange={onChange} />
    ));
  };

  const renderErrorWarnMessage = () => {
    if (errorMessage) {
      return <div className={`message error`}>{errorMessage}</div>;
    }

    if (passwordExpiresInDays) {
      const days = Math.ceil(passwordExpiresInDays);
      const daysString = `${days} ${pluralize(days, 'day', 'days')}`;

      return (
        <div className={`message ${isAlreadyExpired ? 'error' : ''}`}>
          Your password expires in {daysString}. Please reset it below to access your account.
        </div>
      );
    }

    if (isAlreadyExpired) {
      return (
        <div className='message error'>Your password has expired. Please reset it below to access your account.</div>
      );
    }
  };

  return (
    <div className='reset-password'>
      {<SectionHeader heading='Reset Password' caption='Please enter your new password.' />}
      {renderErrorWarnMessage()}
      {renderEmailInputs()}
      <div className={`passwords-match ${!isPasswordsMatched ? 'hide' : ''}`}>Passwords Match</div>
      <RequirementList title='Your password must:' requirements={requirements} />
      <Button
        text='Submit'
        className='yb-primary-button'
        isLoading={isResetInProcess || isLoginInProcess}
        isDisabled={!isPasswordsMatched || !isPasswordValid}
        onClick={onSubmit}
      />
      {isAlreadyExpired === false && (
        <Button text="I'll do it later" className='yb-tertiary-button' onClick={() => navigate('/insured')} />
      )}
    </div>
  );
};

export default ResetPassword;
