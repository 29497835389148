import { LoadingSpinner } from '@yembo/yemblocks-core';
import LogRocket from 'logrocket';
import { lazy, Suspense, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryRegion } from 'src/queries/region';
import { useHideIntercom } from 'src/util/intercom';
import { Employee } from '../../types';
import { isLogRocketEnabled, environment } from '../../util/env';
import { Animation } from './Animation';
import animationData from './Animation/loading.json';
import { Model } from './types';

const KeplerEditor = lazy(() => import('.'));

const MODELS: Model[] = [
  { title: 'KB office', url: '/models/rgbd_kb_office/room.gltf' },
  { title: 'KB office RGB', url: '/models/rgb_kb_office/room.gltf' },
  { title: 'DW room', url: '/models/rgbd_dw_bedroom/room.gltf' },
  { title: 'DW room RGB', url: '/models/rgb_dw_bedroom/room.gltf' },
];

export const assignIdLogRocket = (authUser: Employee): void => {
  if (isLogRocketEnabled) {
    const { key, givenName, familyName, email, phone } = authUser;
    LogRocket.identify(key, {
      name: `${givenName} ${familyName}`,
      email,
      phone: phone ?? '',
      givenName,
      familyName,
    });
  }
};

const getProject = () => {
  if (environment?.includes('prod')) {
    return 'kepler-worker-prod';
  }

  return 'kepler-worker-nonprod';
};

if (isLogRocketEnabled) {
  const project = getProject();

  LogRocket.init(`yembo/${project}`, {
    network: {
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      requestSanitizer: (request) => {
        const hasPassword =
          request.body && typeof request.body === 'string' && request.body.toLowerCase().includes('password');

        if (hasPassword) {
          request.body = undefined;
        }

        return request;
      },
      responseSanitizer: (response) => {
        if (!response.body || typeof response.body !== 'string') {
          return response;
        }

        const hasPrivateData =
          response.body.includes('accessToken') ||
          response.body.includes('landingPageAccessToken') ||
          response.body.includes('twilioAccessToken');

        if (hasPrivateData) {
          response.body = undefined;
        }
        return response;
      },
    },
  });
}

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export function KeplerEditorShell(): JSX.Element {
  const { regionKey } = useParams();
  const queryParams = useQuery();
  const scanKeyParam = queryParams.get('key');

  const { data: region } = useQueryRegion(regionKey);
  const scanKey = scanKeyParam ?? region?.scans?.[0].key;
  const scan = region?.scans?.find(({ key }) => key === scanKey);
  const surveyKey = region?.surveyKey;

  const [modelUrl, setModelUrl] = useState<string | undefined>(regionKey || scanKeyParam ? undefined : MODELS[0].url);

  useHideIntercom();

  const renderFallBack = () => {
    return (
      <div className='kepler-editor'>
        <div className='workspace-wrapper'>
          <div className='workspace'>
            <div className='fake-toolbar'>
              <div className='fake-title'>
                <p>Reticulating splines</p>
                <LoadingSpinner key='top-bar-loader' className='top-spin' />
              </div>
            </div>
            <div className='canvas-wrapper loading'>
              <Animation animationData={animationData} className='loading-screen' key='kyle' />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='app'>
      <Suspense fallback={renderFallBack()}>
        {(scanKey || modelUrl) && (
          <KeplerEditor
            key={modelUrl ?? scanKey}
            title={region?.name ?? MODELS.find((model) => model.url === modelUrl)?.title ?? 'Room'}
            modelUrl={modelUrl}
            models={surveyKey ? [] : MODELS}
            onModelUrlChange={setModelUrl}
            scanKey={modelUrl ? undefined : scanKey}
            surveyKey={surveyKey}
            regionKey={regionKey}
            roomHeight={scan?.roomHeight ?? 2.45}
            scanStatus={scan?.status}
            regionName={region?.name ?? ''}
          />
        )}
      </Suspense>
    </div>
  );
}
