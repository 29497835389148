import { Illustration, Animation } from '@yembo/yemblocks-core';
import { PhoneProps } from '../index';
import dayjs from 'dayjs';
import { getDateInDaysOrderFormat } from 'src/react/_util/dates/dateFormats';
import { useContext } from 'react';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';

export const PhoneContent = ({ imageType }: PhoneProps): JSX.Element => {
  const date = getDateInDaysOrderFormat();
  const time = dayjs().format('h:mm a');
  const { authUser } = useContext(AuthUserContext);

  if (imageType === 'success') {
    return (
      <div className='message-content'>
        <p className='date-wrapper'>
          <span>{date}</span> at {time}
        </p>
        <div className='image-wrapper'>
          {/* Link to the invite image in the CDN so we can keep the file size out of the app bundle */}
          <img className='invite-mms' src='https://media.yembo.ai/mms-assets/invite-mms-template.png' />
        </div>
        {authUser ? (
          <p className='message-text tiny-text'>
            {`${authUser.givenName} ${authUser.familyName}`}, follow the link below to complete your home inspection:
          </p>
        ) : (
          <p className='message-text tiny-text'>Follow the link below to complete your home inspection:</p>
        )}

        <p className='link-text tiny-text'>https://i.yem.bo</p>
        <Illustration type='phone-invite' />
        {authUser && authUser.companies && authUser.companies.length > 0 && authUser.companies[0].logoUrl && (
          <img src={authUser.companies[0].logoUrl} className='company-logo' alt='logo' />
        )}
      </div>
    );
  } else if (imageType === 'phone') {
    return (
      <div className='animated-icons-content'>
        <div>
          <div className='animated-icon'>
            <Animation className='animated-phone' name='phone-text' />
            <div>Receive a survey invite</div>
          </div>
        </div>

        <div>
          <div className='animated-icon'>
            <Animation className='animated-interior' name='phone-scan' />
            <div>Record an interior</div>
          </div>
        </div>

        <div>
          <div className='animated-icon'>
            <Animation className='animated-robot' name='robot' />
            <div>See how our AI works!</div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Illustration className='static-room-content' type='phone-room-video' />;
  }
};
