import {
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Insured, Property, SurveyStatus } from '../types';
import { yemboApiCall, yemboApiCallWithReturnValue } from './index';
import { INSUREDS_QUERY_INFINITE, INSURED_COUNT_QUERY, INSURED_MUTATION, INSURED_QUERY } from './queryKeys';

export const INSURED_LISTS = ['needs-media', 'needs-review', 'all'] as const;

export type InsuredListType = typeof INSURED_LISTS[number];

type PartialInsuredWithKey = Partial<Insured> & Required<Pick<Insured, 'key'>>;

export const FILTERS: Record<InsuredListType, SurveyStatus[]> = {
  'needs-media': [
    'invited',
    'web lead',
    'started survey',
    'created manually',
    'survey scheduled',
    'media requested',
    'partial media',
    'needs media',
  ],
  'needs-review': [
    'ai analyzed',
    'recorded video',
    'completed media',
    'media reviewed',
    'needs review',
    'needs media review',
  ],
  all: [],
};

export const useQueryInsured = (insuredKey: Insured['key'] | undefined) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [INSURED_QUERY, insuredKey],
    queryFn: async () => {
      if (!insuredKey) throw new Error('Unreachable');
      return requestInsured(insuredKey);
    },
    placeholderData: () => {
      const queriesData = queryClient.getQueriesData<{ pages: { insureds: Insured[] }[] }>([INSUREDS_QUERY_INFINITE]);

      for (const [, queryData] of queriesData) {
        const insured = queryData.pages.flatMap(({ insureds }) => insureds).find(({ key }) => key === insuredKey);

        if (insured) return { ...insured, projects: undefined };
      }
    },
    enabled: !!insuredKey,
  });
};

const requestInsured = async (key: Insured['key']): Promise<Insured> => {
  const insured = await yemboApiCallWithReturnValue<Insured, string>({
    url: 'insured',
    urlParams: new URLSearchParams({
      key,
      expandedFields: '["property", "project"]',
    }),
    key: 'insured',
  });

  if (!insured) throw new Error('Insured not found!');

  return insured;
};

export type InsuredObject = {
  companyKey: string;
  insured: PartialInsuredWithKey;
  property: Property;
};

const createInsured = async (insured: InsuredObject): Promise<unknown> => {
  const response = await yemboApiCall({
    url: 'insured/create-contact',
    method: 'POST',
    body: JSON.stringify(insured),
  });
  if (!response.ok) {
    throw new Error('Insured creation failed');
  }

  return (await response.json()) as unknown;
};

export const useCreateInsured = (): UseMutationResult<unknown, unknown, InsuredObject> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [INSURED_MUTATION, ...INSURED_LISTS],
    mutationFn: async (data) => await createInsured(data),
    onSuccess: () => {
      INSURED_LISTS.forEach((eachList) => queryClient.invalidateQueries([INSUREDS_QUERY_INFINITE, eachList]));
    },
  });
};

type InfiniteInsuredsResponse = {
  insureds: Insured[];
  insuredsCount: number;
};

type InfiniteInsuredsContext = QueryFunctionContext<
  [string, InsuredListType, string | undefined, InsuredFilterOptions | undefined, InsuredSortOptions | undefined],
  number
>;

export type InsuredFilterOptions = {
  companyKeys?: string[];
  employeeKeys?: string[];
  type?: 'claims' | 'underwriting';
  date?: string;
  minDate?: string;
  maxDate?: string;
  status?: string[];
  insuredSearch?: string;
};

export type InsuredSortOptions = {
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
};

type InsuredListingProps = {
  employeeKey?: string;
  filterOptions?: InsuredFilterOptions;
  sortOptions?: InsuredSortOptions;
};

const convertArrayToQueryString = (array: string[]): string => {
  return array.length > 0 ? `["${array.join('","')}"]` : '';
};

export const requestInfiniteInsureds = async ({
  context,
  employeeKey,
  filterOptions,
  sortOptions,
}: { context: InfiniteInsuredsContext } & InsuredListingProps): Promise<InfiniteInsuredsResponse> => {
  const listType = context.queryKey[1];
  const { companyKeys = [], employeeKeys = [], status = [], ...restFilters } = filterOptions ?? {};

  const response = await yemboApiCall({
    url: 'insured/all',
    urlParams: new URLSearchParams({
      status: convertArrayToQueryString(listType !== 'all' ? FILTERS[listType] : status),
      page: context && context.pageParam ? context.pageParam.toString() : '1',
      employeeKey: employeeKey ?? '',
      companyKeys: convertArrayToQueryString(companyKeys),
      employeeKeys: convertArrayToQueryString(employeeKeys),
      ...restFilters,
      ...sortOptions,
    }),
  });

  if (!response.ok) {
    throw new Error('Insured list fetching failed');
  }

  return (await response.json()) as InfiniteInsuredsResponse;
};

export const useInfiniteInsuredsQuery = ({
  listType,
  employeeKey,
  filterOptions,
  sortOptions,
}: { listType: InsuredListType } & InsuredListingProps): UseInfiniteQueryResult<InfiniteInsuredsResponse, Error> => {
  return useInfiniteQuery(
    [INSUREDS_QUERY_INFINITE, listType, employeeKey, filterOptions, sortOptions],
    (context: InfiniteInsuredsContext) => requestInfiniteInsureds({ context, employeeKey, filterOptions, sortOptions }),

    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );
};

type InsuredCountResponse = {
  totalNeedsMedia: number;
  totalNeedsReview: number;
  totalAll: number;
  actionCountNeedsMedia: number;
  actionCountNeedsReview: number;
};

const requestInsuredCount = async (employeeKey?: string) => {
  const response = await yemboApiCall({
    url: 'insured/count',
    urlParams: new URLSearchParams({
      needsMediaStatuses: `["${FILTERS['needs-media'].join('","')}"]`,
      needsReviewStatuses: `["${FILTERS['needs-review'].join('","')}"]`,
      employeeKey: employeeKey ? employeeKey : '',
    }),
  });

  if (!response.ok) {
    throw new Error('Insured Count query failed');
  } else if (response.status === 204) {
    return null;
  }

  return (await response.json()) as InsuredCountResponse;
};

export const useInsuredListingCountQuery = (employeeKey?: string): UseQueryResult<InsuredCountResponse | null, Error> =>
  useQuery({
    queryKey: [INSURED_COUNT_QUERY, employeeKey],
    queryFn: () => requestInsuredCount(employeeKey),
  });

const updateInsured = async (insured: PartialInsuredWithKey): Promise<void> => {
  const response = await yemboApiCall({
    url: 'insured',
    method: 'PUT',
    body: JSON.stringify(insured),
  });
  if (!response.ok) {
    throw new Error('Insured update failed');
  }
};

type UseUpdateInsuredResult = UseMutationResult<void, unknown, Partial<Insured>, void>;

type UseUpdateInsuredHandlers = {
  onError?: (e: unknown) => void;
  onSuccess?: () => void;
};

export const useUpdateInsured = (
  insuredKey: string,
  { onError, onSuccess }: UseUpdateInsuredHandlers
): UseUpdateInsuredResult & { mutateAsyncNoThrow: UseUpdateInsuredResult['mutateAsync'] } => {
  const queryClient = useQueryClient();
  const optimisticUpdateQueryKey = [INSURED_QUERY, insuredKey];
  const insured = queryClient.getQueryData<Insured>(optimisticUpdateQueryKey);

  const mutation = useMutation(
    async (data: Partial<Insured>) => {
      if (insuredKey) return await updateInsured({ ...data, key: insuredKey });
      else throw new Error('UpdateVersionRequest: Wrong Key passed');
    },
    {
      mutationKey: [INSURED_MUTATION, optimisticUpdateQueryKey],
      onMutate: (updatedData) => {
        if (insured) {
          queryClient.setQueryData<Insured>(optimisticUpdateQueryKey, { ...insured, ...updatedData });
        }
      },
      onError: (e) => {
        onError?.(e);

        if (insured) {
          queryClient.setQueryData(optimisticUpdateQueryKey, insured);
        }
      },
      onSuccess,
      onSettled: () => {
        queryClient.invalidateQueries(optimisticUpdateQueryKey);
        queryClient.invalidateQueries([INSUREDS_QUERY_INFINITE]);
      },
    }
  );

  return {
    ...mutation,
    mutateAsyncNoThrow: async (...params) => {
      try {
        return mutation.mutateAsync(...params);
      } catch {
        /* ignore error */
      }
    },
  };
};

const deleteInsured = async (insuredKey: Insured['key']) => {
  const response = await yemboApiCall({
    url: 'insured',
    method: 'DELETE',
    body: JSON.stringify({ key: insuredKey }),
  });

  if (!response.ok) {
    throw new Error('Insured deletion failed');
  }
};

type DeleteMutationResult = UseMutationResult<void, unknown, Insured['key']>;
export const useDeleteInsured = (): DeleteMutationResult & {
  mutateAsyncNoThrow: DeleteMutationResult['mutateAsync'];
} => {
  const queryClient = useQueryClient();

  const mutation: DeleteMutationResult = useMutation({
    mutationKey: [INSURED_MUTATION],
    mutationFn: (insuredKey) => deleteInsured(insuredKey),
    onSuccess: () => {
      queryClient.invalidateQueries([INSUREDS_QUERY_INFINITE]);
    },
  });

  return {
    ...mutation,
    mutateAsyncNoThrow: async (...params) => {
      try {
        return mutation.mutateAsync(...params);
      } catch {
        /* ignore error */
      }
    },
  };
};

type CheckDuplicatesResponse = {
  status: {
    message?: string | undefined;
    type?: string;
  }[];
  hasPotentialDuplicate: boolean;
  insured: Insured;
  surveyKey: string;
};

export const useCheckDuplicates = () =>
  useMutation({
    mutationFn: async (insured: Partial<Insured>): Promise<CheckDuplicatesResponse | undefined> => {
      const response = await yemboApiCall({
        url: 'insured/check-duplicates',
        method: 'PUT',
        body: JSON.stringify(insured),
      });

      if (!response.ok) return;

      return (await response.json()) as CheckDuplicatesResponse;
    },
  });

export const checkValidatePhoneAPI = async (data: { phone: string; companyKey: string }): Promise<boolean> => {
  const response = await yemboApiCall({
    url: 'invite/validate-phone',
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Failed to validate phone!');
  }

  const body = (await response.json()) as { canSendSms: boolean };
  return body.canSendSms;
};
