import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Company } from 'src/types';
import { queryClient, yemboApiCall, yemboApiCallWithReturnValue } from '.';
import { COMPANIES_QUERY, COMPANY_MUTATION, COMPANY_QUERY } from './queryKeys';

const url = 'company';

const readCompanies = async (): Promise<Company[]> =>
  (await yemboApiCallWithReturnValue({
    key: 'companies',
    url: `${url}/list-all`,
  })) ?? [];

export const useQueryCompanies = (options?: UseQueryOptions<Company[], Error>) =>
  useQuery<Company[], Error>({
    ...options,
    queryKey: [COMPANIES_QUERY],
    queryFn: readCompanies,
  });

type DeleteResponse = {
  status: {
    message?: string | undefined;
    type?: string | undefined;
  }[];
};

export type CompanyResponse = {
  status: {
    message?: string | undefined;
    type?: string;
  }[];
  company: Company;
};

const createCompany = async (data: Partial<Company>): Promise<CompanyResponse> => {
  const response = await yemboApiCall({
    url,
    method: 'POST',
    body: JSON.stringify(data),
  });

  const body = (await response.json()) as CompanyResponse;

  if (!response.ok) {
    const { message = 'Failed to create company!' } = body.status[0];
    throw new Error(message);
  }

  return body;
};

export const useCreateCompany = (): UseMutationResult<
  CompanyResponse,
  unknown,
  Partial<Company>,
  { previous?: Company[] }
> => {
  return useMutation({
    mutationKey: [COMPANY_MUTATION],
    mutationFn: async (data) => await createCompany(data),
    onSuccess: async (response) => {
      const queryKey = [COMPANIES_QUERY];

      await queryClient.cancelQueries(queryKey);

      const previous = queryClient.getQueryData<Company[]>(queryKey);

      if (previous) {
        queryClient.setQueryData<Company[]>(queryKey, [response.company, ...previous]);
      }

      return { previous };
    },
    onError: (error, company, context) => {
      if (context?.previous) {
        queryClient.setQueryData<Company[]>([COMPANIES_QUERY], context.previous);
      }
    },
  });
};

const updateCompany = async (company: Partial<Company>): Promise<void> => {
  const response = await yemboApiCall({
    url,
    method: 'PUT',
    body: JSON.stringify({ ...company }),
  });

  const body = (await response.json()) as DeleteResponse;

  if (!response.ok) {
    const { message = 'Failed to update company!' } = body.status[0];
    throw new Error(message);
  }
};

export class CompanyMutationError extends Error {
  constructor(message: string, readonly type: DeleteResponse['status'][0]['type']) {
    super(message);
  }
}

const deleteCompany = async (company: CompanyWithPassword): Promise<void> => {
  const response = await yemboApiCall({
    url,
    method: 'DELETE',
    body: JSON.stringify({ ...company }),
  });

  const body = (await response.json()) as DeleteResponse;

  if (!response.ok) {
    const { message = 'Failed to delete company!', type } = body.status[0];
    throw new CompanyMutationError(message, type);
  }
};

type CompanyWithPassword = Partial<Company> & { password: string };
type MutationResult<A> = UseMutationResult<void, unknown, A, { previous?: Company[] }>;

function useCompanyMutation<A extends Partial<Company>>(
  mutate: (company: A) => Promise<void>,
  updateOptimistic: (company: A, previous: Company[]) => Company[],
  onError?: (error: unknown) => void
): MutationResult<A> {
  const queryClient = useQueryClient();

  const mutation: MutationResult<A> = useMutation({
    mutationKey: [COMPANY_MUTATION],
    mutationFn: async (company) => await mutate(company),
    onMutate: async (company) => {
      const queryKey = [COMPANIES_QUERY];
      await queryClient.cancelQueries(queryKey);

      const previous = queryClient.getQueryData<Company[]>(queryKey);

      if (previous) {
        queryClient.setQueryData<Company[]>(queryKey, updateOptimistic(company, previous));
      }

      return { previous };
    },
    onError: (error, company, context) => {
      onError?.(error);
      if (context?.previous) {
        queryClient.setQueryData<Company[]>([COMPANIES_QUERY], context.previous);
      }
    },
  });

  return {
    ...mutation,
  };
}

export const useUpdateCompany = (onError?: (error: unknown) => void): MutationResult<Partial<Company>> =>
  useCompanyMutation(
    updateCompany,
    (company, previous) =>
      previous?.map((previousCompany) =>
        previousCompany.key === company.key ? { ...previousCompany, ...company } : previousCompany
      ),
    onError
  );

export const useDeleteCompany = (onError?: (error: unknown) => void): MutationResult<CompanyWithPassword> =>
  useCompanyMutation(
    deleteCompany,
    (company, previous) => previous.filter((previousCompany) => previousCompany.key !== company.key),
    onError
  );

type CompanyWithCustomizations = Company & Required<Pick<Company, 'customizations'>>;

const readCompany = async (key: string) => {
  const company = await yemboApiCallWithReturnValue<CompanyWithCustomizations, 'company'>({
    url: `${url}/`,
    urlParams: new URLSearchParams({
      key,
      expandedFields: '["customizations"]',
    }),
    key: 'company',
  });

  if (!company) throw new Error('Company not found');

  return company;
};

export const useQueryCompany = (key?: string): UseQueryResult<CompanyWithCustomizations, Error> =>
  useQuery({
    queryKey: [COMPANY_QUERY, key],
    queryFn: () => {
      if (!key) throw new Error('Unreachable');
      return readCompany(key);
    },
    enabled: !!key,
  });
