import { Button, TextFieldPassword } from '@yembo/yemblocks-core';
import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';
import { LoginFormData } from '../';
import { verifyMfa } from '../../_components/AuthUserProvider/api/auth';
import { LockedLoginMessage } from '../LoginForm';
import { SectionHeader } from '../SectionHeader';
import { useSubmitOnEnterKey } from '../_util/useEnterKeySubmit';

interface Props {
  formData: LoginFormData;
  setFormData: Dispatch<SetStateAction<LoginFormData>>;
}

export const VerifyBackupCode = ({ formData, setFormData }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { setAuthUser } = useContext(AuthUserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false); // local loading

  const [backupCode, setBackupCode] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | JSX.Element>();
  const [locked, setLocked] = useState(false);

  const backupCodeField = (
    <TextFieldPassword
      name='backupCode'
      label='Backup code'
      defaultValue={formData.backupCode}
      onChange={(event) => {
        setBackupCode(event.target.value);
        if (isError) {
          setIsError(false);
        }
      }}
      isValid={!isError}
      errorMessage=' '
    />
  );

  const isValidBackupCode = useCallback((code: string) => {
    if (code.length === 16 && /^[a-zA-Z0-9]+$/.test(code)) return true;
    else return false;
  }, []);

  const onLogin = useCallback(() => {
    if (!isValidBackupCode(backupCode)) {
      setErrorMessage('Incorrect code');
      setIsError(true);
      return;
    }

    const verifyWithLoading = async (): Promise<void> => {
      setIsLoading(true);
      formData.mfaCode = '';
      try {
        await verifyMfa({ ...formData, backupCode }, navigate, setAuthUser);
      } catch ({ message }) {
        const isUserLocked = typeof message === 'string' && message.includes('has been locked');

        if (isUserLocked) {
          setErrorMessage(<LockedLoginMessage />);
          setFormData({ ...formData, backupCode: '' });
          setIsError(false);
        } else {
          setIsError(true);
        }

        setLocked(isUserLocked);
        setIsLoading(false);
      }
    };

    verifyWithLoading();
  }, [formData, backupCode, isValidBackupCode, navigate, setAuthUser, setFormData]);

  useSubmitOnEnterKey({ onSubmit: onLogin });

  return (
    <div className='verify-backup-code'>
      <SectionHeader
        heading='Verify Account'
        caption='Enter your backup code below to log in.'
        showError={isError || !!errorMessage}
        errorMessage={errorMessage ?? 'Incorrect code'}
      />
      <div className={`fields-container ${locked ? 'locked' : ''}`}>
        {backupCodeField}
        <Button text='Login' className='yb-primary-button login-btn' isLoading={isLoading} onClick={onLogin} />
        <Button
          text='Back'
          className='yb-tertiary-button back-btn'
          size='small'
          onClick={() => navigate('/verify-mfa')}
          isDisabled={isLoading}
        />
      </div>
    </div>
  );
};
