import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { InsuredConfigContext } from 'src/react/Kepler/CreateInsured/_contexts/InsuredConfigProvider';
import { InsuredContext } from '../_contexts/InsuredProvider';
import { Error } from './Error';
import { Success } from './Success';

interface Props {
  resultState: 'success' | 'error' | 'idle' | 'loading';
  createdInsuredKey: string;
  onClickAction: () => void;
  successProps?: {
    viewButtonText: string;
    actionButtonText: string;
    resultText: string;
  };
}

export const CreationResult = ({ resultState, onClickAction, createdInsuredKey, successProps }: Props): JSX.Element => {
  const { clearFormData } = useContext(InsuredContext);
  const { closeInsuredForm } = useContext(InsuredConfigContext);

  const navigate = useNavigate();
  const navigateToInsured = () => navigate(`/insured/${createdInsuredKey}/overview`);

  const viewCreatedInsured = () => {
    navigateToInsured();
    closeInsuredForm();
  };

  const addAnotherInsured = () => {
    onClickAction();
    clearFormData();
  };

  if (resultState === 'success' && successProps) {
    return <Success {...successProps} onActionClick={addAnotherInsured} onViewCreated={viewCreatedInsured} />;
  }
  return <Error onClickAction={onClickAction} />;
};
