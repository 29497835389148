import { useEffect, useState } from 'react';
import lottie from 'lottie-web';

export function Animation({
  animationData,
  className,
  loop = true,
}: {
  animationData: Record<string, unknown>;
  className: string;
  loop?: boolean;
}): JSX.Element {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!container) return;

    const animation = lottie.loadAnimation({
      container,
      animationData,
      renderer: 'svg',
      loop,
      autoplay: true,
    });

    return () => animation.destroy();
  }, [container, animationData, loop]);

  return <div className={className} ref={setContainer} />;
}
