import { LoadingSpinner } from '@yembo/yemblocks-core';
import { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';
import { readAuthUser } from 'src/react/_components/AuthUserProvider/api/auth';

export const PrivateRoute = (): JSX.Element | null => {
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const [isLoading, setIsLoading] = useState(!authUser);
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    const loadAuthUser = async () => {
      if (accessToken && !authUser) {
        setIsLoading(true);
        await readAuthUser(setAuthUser);
        setIsLoading(false);
      } else if (!accessToken && !authUser) {
        setIsLoading(false);
      }
    };
    loadAuthUser();
  }, [accessToken, authUser, setAuthUser]);

  if (isLoading) {
    return (
      <div className='auth-spinner-container'>
        <LoadingSpinner label='Logging in...' />
      </div>
    );
  }

  if (!authUser) {
    return <Navigate to='/login' state={{ from: `${location.pathname}${location.search}${location.hash}` }} />;
  }

  return <Outlet />;
};
