import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { SampleSurveyData } from 'src/react/Kepler/CreateInsured/_util/getSampleSurveyData';
import { yemboApiCall, yemboApiCallWithReturnValue } from '.';
import { FloorPlanFileType, Project, Survey } from '../types';

import {
  INSUREDS_QUERY_INFINITE,
  INSURED_MUTATION,
  PROJECT_QUERY,
  SURVEY_QUERY,
  SURVEY_UPDATE_MUTATION,
} from './queryKeys';

export type FloorPlanResponse = {
  status: [type: string];
  version: number;
};

const requestSurvey = async (key: Survey['key']): Promise<Survey> => {
  const survey = await yemboApiCallWithReturnValue<Survey, string>({
    url: 'survey',
    urlParams: new URLSearchParams({
      key,
      expandedFields: '["insured"]',
    }),
    key: 'survey',
  });

  if (!survey) throw new Error('Survey not found!');

  return survey;
};

export const requestFloorplan = async (key: Survey['key'], type: FloorPlanFileType): Promise<FloorPlanResponse> => {
  const response = await yemboApiCall({
    url: 'survey/generate-floorplan',
    method: 'PUT',
    body: JSON.stringify({ key, type }),
  });

  const body = (await response.json()) as FloorPlanResponse | null;

  if (!response.ok || !body) {
    throw new Error('Floor plan request failed');
  }

  return body;
};

export const useQuerySurveyPoll = (
  surveyKey: string | undefined,
  version: number | undefined
): UseQueryResult<Survey, Error> => {
  return useQuery({
    queryKey: ['floorPlanProgress', surveyKey, String(version)],
    queryFn: () => {
      if (surveyKey == null || version === undefined) throw new Error('Unreachable');
      return requestSurvey(surveyKey);
    },
    onError: () => {
      throw new Error('An error occurred processing');
    },
    enabled: surveyKey != null && version !== undefined,
    refetchInterval: 700,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });
};

export const useQuerySurvey = (surveyKey: string): UseQueryResult<Survey, Error> =>
  useQuery({
    queryKey: [SURVEY_QUERY, surveyKey],
    queryFn: () => requestSurvey(surveyKey),
  });

export type SurveyListQueryData = { pageParams: unknown; pages: Survey[][] };

export type SampleSurveyResponse = {
  status: {
    message?: string | undefined;
    type?: string;
  }[];
  survey: Survey;
};

const createSampleSurvey = async (survey: SampleSurveyData): Promise<SampleSurveyResponse> => {
  const response = await yemboApiCall({
    url: '/insured/demo',
    method: 'POST',
    body: JSON.stringify(survey),
  });

  if (!response.ok) {
    throw new Error('Survey creation failed');
  }

  return (await response.json()) as SampleSurveyResponse;
};

export const useCreateSampleSurvey = (): UseMutationResult<SampleSurveyResponse, unknown, SampleSurveyData> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [INSURED_MUTATION],
    mutationFn: async (data) => await createSampleSurvey(data),
    onSuccess: () => {
      queryClient.invalidateQueries([INSUREDS_QUERY_INFINITE]);
    },
  });
};

type PartialSurvey = Partial<Survey> & Pick<Survey, 'key' | 'projectKey'>;

const updateSurvey = async (survey: PartialSurvey): Promise<void> => {
  const response = await yemboApiCall({
    url: '/survey',
    method: 'PUT',
    body: JSON.stringify({ ...survey }),
  });

  if (!response.ok) {
    throw new Error('Survey update failed.');
  }
};

export const useUpdateSurvey = (): UseMutationResult<void, unknown, PartialSurvey> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [SURVEY_UPDATE_MUTATION],
    mutationFn: async (survey) => await updateSurvey(survey),
    onMutate: async (survey) => {
      await queryClient.cancelQueries([PROJECT_QUERY, survey.projectKey]);
      const previousProject = queryClient.getQueryData<Project>([PROJECT_QUERY, survey.projectKey]);

      if (previousProject) {
        queryClient.setQueryData<Project>([PROJECT_QUERY, survey.projectKey], {
          ...previousProject,
          surveys: previousProject.surveys?.map((s) => (s.key === survey.key ? { ...s, ...survey } : s)),
        });
      }

      return { previousProject };
    },
    onError: (error, survey, context) => {
      queryClient.setQueryData([PROJECT_QUERY, survey.projectKey], context?.previousProject);
    },
  });
};
