export const COMPANIES_QUERY = 'companies-query';
export const COMPANY_QUERY = 'company-query';
export const CUSTOMIZATIONS_QUERY = 'customizations-query';
export const COMPANY_MUTATION = 'company-mutation';
export const INSUREDS_QUERY_INFINITE = 'insureds-query-infinite';
export const INSURED_QUERY = 'insured-query';
export const INSURED_COUNT_QUERY = 'insured-count-query';
export const INSURED_MUTATION = 'insured-mutation';
export const MESSAGING_MUTATION = 'messaging-mutation';
export const MESSAGING_QUERY = 'messaging-query';
export const SURVEY_QUERY = 'survey-query';
export const SURVEY_MUTATION = 'survey-mutation';
export const SURVEY_UPDATE_MUTATION = 'survey-update-mutation';
export const REGION_QUERY = 'region-query';
export const REGION_MUTATION = 'region-mutation';
export const SCANS_QUERY = 'scans-query';
export const SCANS_QUERY_INFINITE = 'scans-query-infinite';
export const SCANS_MUTATION = 'scans-mutation';
export const VIDEOS_QUERY = 'videos-query';
export const EMPLOYEE_LIST_QUERY = 'employee-list-query';
export const EMPLOYEE_MUTATION = 'employee-mutation';
export const SPECIALTY_ITEMS_MUTATION = 'specialty-items-mutation';
export const SPECIALTY_ITEM_QUERY = 'specialty-item-query';
export const PROJECT_MUTATION = 'project-mutation';
export const PROJECT_QUERY = 'project-query';
export const PROPERTY_QUERY = 'property-query';
export const PROPERTY_MUTATION = 'property-mutation';
export const AUTH_USER_QUERY = 'auth-user-query';
export const AUTH_USER_MUTATION = 'auth-user-mutation';
export const ANNOTATIONS_QUERY = 'annotations-query';
export const ANNOTATION_MUTATION = 'annotation-mutation';
export const POLYGON_MUTATION = 'polygon-mutation';
export const BOOKMARKS_QUERY = 'bookmarks-query';
export const BOOKMARK_MUTATION = 'bookmarks-mutation';
export const GENERATE_BACKUP_CODES = 'generate-backup-codes';
export const REQUEST_RESET_PASSWORD = 'request-reset-password';
export const RESET_PASSWORD = 'reset-password';
export const FORCE_RESET_PASSWORD = 'force-reset-password';
export const CLASSIFICATION_QUERY = 'classification-query';
export const TAG_MUTATION = 'tag-mutation';
export const IMAGE_MUTATION = 'image-mutation';
export const POSED_IMAGE_QUERY = 'posed-image-query';
export const POSED_IMAGE_MUTATION = 'posed-image-mutation';
export const ANNOTATION_TASKS_QUERY_INFINITE = 'annotation-tasks-query-infinite';
export const ANNOTATION_TASK_MUTATION = 'annotation-task-mutation';
export const CREATE_IMAGE = 'create-image';
export const CREATE_SNAPSHOT_IMAGE = 'create-snapshot-image';
export const TEXT_THREAD_QUERY = 'text-thread-query';
export const TEXT_MESSAGE_MUTATION = 'text-message-mutation';
export const TEXT_MESSAGE_MARK_READ = 'text-message-mark-read';
export const TEXT_MESSAGE_SEND = 'text-message-send';
export const DATASETS_QUERY_INFINITE = 'datasets-query-infinite';
export const DATASET_MUTATION = 'dataset-mutation';
export const DATASET_QUERY = 'dataset-query';
