import { BannerNotificationType, Button, Illustration, TextFieldPassword, TextFieldPhone } from '@yembo/yemblocks-core';
import { determine } from 'jstz';
import { SetStateAction, useContext, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useResetPassword } from 'src/queries/employee';
import { useCreateInvite } from 'src/queries/invite';
import { initialFormData } from 'src/react/Kepler/CreateInsured/_contexts/InsuredProvider';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';
import { login, readAuthUser } from 'src/react/_components/AuthUserProvider/api/auth';
import { RequirementList } from 'src/react/_components/RequirementList';
import { generateKey } from 'src/util/generateKey';
import { useUpdateAuthUser } from '../../../queries/employee';
import { IconProgressBar } from '../../_components/IconProgressBar';
import { getPasswordRequirements } from '../ResetPassword';
import { useSubmitOnEnterKey } from '../_util/useEnterKeySubmit';
import { AuthHeader } from './components/AuthHeader';
import { Phone } from './components/Phone';

export type PageType = 'password' | 'phone' | 'success' | 'error';

type State = {
  password: string;
  confirmPassword: string;
  phone: string;
  isPhoneValid: boolean;
  page: PageType;
  isLoginInProcess: boolean;
};

const reducer = (state: State, action: Partial<State>) => ({ ...state, ...action });

interface Props {
  setAlert: React.Dispatch<SetStateAction<{ type: BannerNotificationType; text: string } | null>>;
}

export const AccountActivation = ({ setAlert }: Props): JSX.Element => {
  const navigate = useNavigate();

  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const { email, token, activationToken } = useParams();

  const { mutateAsync: createInvite, isLoading: isInviteLoading, isError: isInviteError } = useCreateInvite();
  const { mutateAsync: resetPassword, isLoading: isPasswordLoading, isError: IsPasswordError } = useResetPassword();
  const { mutateAsync: updateEmployee, isLoading: isUpdateLoading, isError: isUpdateError } = useUpdateAuthUser();

  const [{ password, confirmPassword, phone, isPhoneValid, page, isLoginInProcess }, dispatchState] = useReducer(
    reducer,
    {
      password: '',
      confirmPassword: '',
      phone: '',
      isPhoneValid: false,
      page: 'password',
      isLoginInProcess: false,
    }
  );

  const timezone = determine().name() || 'UTC';
  const isPasswordsMatched = password && password === confirmPassword;
  const { requirements, isPasswordValid } = getPasswordRequirements({ password, email });

  const setLoginAlert = () =>
    setAlert({ type: 'error', text: 'Something went wrong... Login failed. Please try later.' });

  useEffect(() => {
    if (IsPasswordError) dispatchState({ page: 'error' });

    if (isUpdateError)
      setAlert({ type: 'error', text: "Something went wrong... Can't update your data. Please try later." });

    if (isInviteError)
      setAlert({ type: 'error', text: "Something went wrong... Can't send invite. Please try later." });
  }, [IsPasswordError, isUpdateError, isInviteError, setAlert]);

  const onLogin = async () => {
    if (!email) return;

    try {
      const partialAuthUser = { ...authUser };
      delete partialAuthUser.phone;
      authUser && !['superadmin', 'admin'].includes(authUser.role) && delete partialAuthUser.role;

      dispatchState({ isLoginInProcess: true });
      const accessToken = await login({ email: decodeURIComponent(email), password }, timezone, navigate, setAuthUser);
      if (accessToken) await readAuthUser(setAuthUser);

      await updateEmployee({ ...partialAuthUser });
      navigate('/');
    } catch {
      dispatchState({ isLoginInProcess: false });
      setLoginAlert();
    }
  };

  const onSubmit = async () => {
    if (!email) return;

    switch (page) {
      case 'password':
        {
          if (!token || !isPasswordValid || !isPasswordsMatched) return;
          const data = {
            email: decodeURIComponent(email),
            password,
            passwordResetLink: token,
          };

          await resetPassword(data);

          try {
            dispatchState({ isLoginInProcess: true });

            await login(
              { email: decodeURIComponent(email), password, activationToken },
              timezone,
              navigate,
              setAuthUser
            );

            dispatchState({ page: 'phone', isLoginInProcess: false });
          } catch {
            setLoginAlert();
          }
        }

        break;
      case 'phone':
        if (!isPhoneValid && phone.length < 6) return;
        await updateEmployee({ phone: `${phone}` });

        if (authUser?.companyKeys && authUser.companyKeys.length > 0) {
          await createInvite({
            ...initialFormData,
            companyKey: authUser.companyKeys[0],
            shouldSendEmail: false,
            shouldSendSms: true,
            inviteType: 'underwriting',
            insured: {
              key: generateKey('insured'),
              givenName: authUser.givenName,
              familyName: authUser.familyName,
              email: authUser.email,
              phone,
            },

            survey: {
              key: generateKey('survey'),
              employeeKey: authUser.key,
            },
          });
        }
        dispatchState({ page: 'success' });

        break;
      case 'success':
        await onLogin();
    }
  };

  useSubmitOnEnterKey({ onSubmit });

  const inputs =
    page === 'password' ? (
      <>
        <TextFieldPassword
          name={'password'}
          label={'Password'}
          onChange={(event) => dispatchState({ password: event.target.value })}
        />
        <TextFieldPassword
          name={'confirmPassword'}
          label={'Confirm Password'}
          onChange={(event) => dispatchState({ confirmPassword: event.target.value })}
        />
      </>
    ) : (
      <TextFieldPhone
        onSubmitCallback={() => ''}
        onChangeCallback={(phone: string, isPhoneValid: boolean) => dispatchState({ phone, isPhoneValid })}
      />
    );

  const renderForm = () => {
    switch (page) {
      case 'password':
        return (
          <div className='activate-page-body'>
            <Phone imageType='password' />
            <IconProgressBar stepsCompleted={0} />
            <div className='activate-page-form'>
              <AuthHeader pageType='password' />
              <div className='activate-page-form-inputs'>{inputs}</div>
              <div className={`passwords-match ${!isPasswordsMatched ? 'hide' : 'tiny-text'}`}>Passwords Match</div>
              <RequirementList title='Your password must:' requirements={requirements} />
              <Button
                text='Next'
                className='yb-primary-button next-password-button'
                isLoading={isPasswordLoading || isLoginInProcess}
                isDisabled={!isPasswordsMatched || !isPasswordValid}
                onClick={onSubmit}
              />
            </div>
          </div>
        );

      case 'phone':
        return (
          <div className='activate-page-body phone-body'>
            <Phone imageType='phone' />
            <IconProgressBar stepsCompleted={1} />
            <div className='activate-page-form'>
              <AuthHeader pageType='phone' />
              <div className='activate-page-form-inputs phone-input'>{inputs}</div>
              <p className='tiny-text agreement-text'>
                By clicking "Next", you consent for Yembo.ai and partners to use automated technology, including
                pre-recorded messages, emails, cell phones and texts, to contact you at the number provided. This
                includes if the number is currently on any Do Not Call Lists. This consent is not required to make a
                purchase.
              </p>
              <Button
                text='Next'
                className='yb-primary-button next-phone-button'
                isLoading={isUpdateLoading || isInviteLoading}
                isDisabled={!isPhoneValid || phone.length < 6}
                onClick={onSubmit}
              />
              <Button
                text='I’ll do this later'
                className='yb-tertiary-button do-later-button'
                isLoading={isLoginInProcess}
                onClick={onLogin}
              />
            </div>
          </div>
        );

      case 'success':
        return (
          <div className='activate-page-body'>
            <Phone imageType='success' />
            <IconProgressBar stepsCompleted={3} />
            <div className='activate-page-form'>
              <AuthHeader pageType='success' />
              <Illustration type='paper-airplane' />
              <Button
                text='Got it, Take me to Yembo'
                className='yb-primary-button success-button'
                isLoading={isLoginInProcess}
                onClick={onSubmit}
              />
              <div className='button-wrapper'>
                <span>Didn’t get it? </span>
                <Button
                  text='Re-enter your number'
                  className='yb-tertiary-button re-enter-button'
                  onClick={() => dispatchState({ page: 'phone' })}
                />
              </div>
            </div>
          </div>
        );
      case 'error':
        return (
          <div className='activate-page-body'>
            <Phone imageType='password' />
            <IconProgressBar stepsCompleted={0} />
            <div className='activate-page-form'>
              <AuthHeader pageType='error' />
              <div className='error-image'>
                <Illustration type='invalid-link' />
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className='activate-page-container'>
      <div className='activate-page-content '>{renderForm()}</div>
    </div>
  );
};
