import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { Insured, Project, Property, Survey, SurveyType } from 'src/types';
import { yemboApiCall } from '.';
import { INSUREDS_QUERY_INFINITE, INSURED_MUTATION, INSURED_QUERY, PROJECT_QUERY, SURVEY_MUTATION } from './queryKeys';

const URL = 'invite';

export type AffectedArea = {
  label: string;
  name: string;
  ordinal: number;
};

export type ProjectInviteData = {
  companyKey: Insured['companyKey'];
  insuredKey: Insured['key'];
  survey: Partial<Survey> & Pick<Survey, 'key' | 'employeeKey'>;
  project: Partial<Project> & Pick<Project, 'key' | 'type' | 'employeeKey'>;
  property: Partial<Property> & { key: Property['key'] };
  shouldSendEmail: boolean;
  shouldSendSms: boolean;
  regions?: AffectedArea[];
};

export type InviteData = {
  inviteType: SurveyType;
  companyKey: string;
  surveyKey?: string;
  insured: Partial<Insured> & Pick<Insured, 'key' | 'givenName' | 'familyName'>;
  survey: Partial<Survey> & Pick<Survey, 'key' | 'employeeKey'>;
  property: Partial<Property>;
  shouldSendEmail: boolean;
  shouldSendSms: boolean;
};

export type ResendInviteData = {
  inviteType?: SurveyType;
  companyKey: string;
  surveyKey?: string;
  shouldSendEmail: boolean;
  shouldSendSms: boolean;
};

export type InviteResponse = {
  status: {
    message?: string | undefined;
    type?: string;
  }[];
  survey: Survey;
  code?: string;
  link?: string;
};

const createProjectInvite = async (data: ProjectInviteData) => {
  const response = await yemboApiCall({
    url: `${URL}/project`,
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Invite creation failed');
  }

  return (await response.json()) as InviteResponse & { link: string; project: Project };
};

type MutationResult = UseMutationResult<
  InviteResponse & { link: string; project: Project },
  unknown,
  ProjectInviteData,
  {
    previous?: Insured;
  }
>;

export const useCreateProjectInvite = (insuredKey: string, onError?: (error: unknown) => void): MutationResult => {
  const queryClient = useQueryClient();

  const mutation: MutationResult = useMutation({
    mutationKey: [INSURED_MUTATION, insuredKey],
    mutationFn: async (data: ProjectInviteData) => await createProjectInvite(data),
    onMutate: async (data) => {
      const queryKey = [INSURED_QUERY, insuredKey];
      await queryClient.cancelQueries(queryKey);

      const previous = queryClient.getQueryData<Insured>(queryKey);

      if (previous) {
        const projects = [...(previous.projects ?? []), data.project];
        const newInsured = { ...previous, projects } as Insured;
        queryClient.setQueryData<Insured>(queryKey, newInsured);
      }

      return { previous };
    },
    onError: (error, data, context) => {
      onError?.(error);

      if (context?.previous) {
        queryClient.setQueryData<Insured>([INSURED_QUERY, data.insuredKey], context.previous);
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([INSURED_QUERY, variables.insuredKey]);
      queryClient.invalidateQueries([PROJECT_QUERY, data.project.key]);
      queryClient.invalidateQueries([INSUREDS_QUERY_INFINITE]);
    },
  });

  return mutation;
};

const createInvite = async (data: InviteData | ResendInviteData): Promise<InviteResponse> => {
  const response = await yemboApiCall({
    url: 'invite',
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Invite creation failed');
  }

  return (await response.json()) as InviteResponse;
};

export const useCreateInvite = (): UseMutationResult<InviteResponse, unknown, InviteData> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [SURVEY_MUTATION],
    mutationFn: async (data) => await createInvite(data),
    onSuccess: () => {
      queryClient.invalidateQueries([INSUREDS_QUERY_INFINITE]);
    },
  });
};

export const useResendInvite = (): UseMutationResult<InviteResponse, unknown, ResendInviteData, unknown> => {
  return useMutation({
    mutationKey: [SURVEY_MUTATION],
    mutationFn: async (data) => await createInvite(data),
  });
};
