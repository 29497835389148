import { Modal, ModalHeader } from '@yembo/yemblocks-core';
import { useState } from 'react';
import { useWindowDimensions } from 'src/react/_hooks/useWindowDimensions';

// The modal window should only appear on phones, due to the small screen.
// Minimum IOS tablet viewport width (height in lansdcape mode) is 625 (Ipad mini 2021 in Chrome).
const MIN_WIDTH = 625;

export const MobileWarningModal = (): JSX.Element | null => {
  const { width, height } = useWindowDimensions();

  //Get screen dimension depends on device orientation
  const currentDimension = width > height ? height : width;
  const [isOpen, setIsOpen] = useState(currentDimension < MIN_WIDTH);

  return isOpen ? (
    <Modal className='mobile-warning-modal' onClose={() => setIsOpen(false)}>
      <ModalHeader headerText='Heads up!' />
      <p>
        This app is not optimized for mobile devices. Please log into your account from a laptop or desktop computer.
      </p>
    </Modal>
  ) : null;
};
