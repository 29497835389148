// Makes 100vh height on mobile devices to be without scroll
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

let prevInnerHeight = -1;

function updateVH() {
  if (prevInnerHeight !== window.innerHeight) {
    prevInnerHeight = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }
}

export function fixMobileViewportUnits(): void {
  const fixMobileViewportUnitsHandler = () => {
    updateVH();

    // https://openradar.appspot.com/radar?id=5040881597939712
    // 500ms additional dimensions check for Safari
    setTimeout(updateVH, 500);
  };

  fixMobileViewportUnitsHandler();

  window.addEventListener('resize', fixMobileViewportUnitsHandler);
}
