import { useState, useEffect } from 'react';

export type Dimensions = { width: number; height: number };

function getWindowDimensions(): Dimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export const useWindowDimensions = (): Dimensions => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(
    // https://openradar.appspot.com/radar?id=5040881597939712
    // 500ms additional dimensions check for Safari

    () => {
      const timeout = setTimeout(() => {
        const newDimensions = getWindowDimensions();
        const { width, height } = dimensions;

        if (width !== newDimensions.width || height !== newDimensions.height) {
          setDimensions(newDimensions);
        }
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    },
    [dimensions]
  );

  return dimensions;
};
