import { getFullName } from 'src/react/_util';
import { Employee } from 'src/types';
import { UserDataLogRocketType } from 'src/analytics/logRocket';

export const getSessionData = (authUser: Employee): UserDataLogRocketType => {
  const { email, phone, givenName, familyName, key } = authUser;

  const name = getFullName(authUser);

  const {
    githubInfo = { commit: '' },
    circleCiInfo: circleCi = { buildLink: '' },
    browserInfo = { name: '', version: '' },
    osInfo = { name: '', version: '' },
    buildInfo = '',
    resolution = '',
    ramInfo = '',
    locale = '',
  } = window;

  const githubLink = githubInfo.commit || '';
  const circleCiInfo = circleCi.buildLink || '';
  const browserName = browserInfo.name || '';
  const browserVersion = browserInfo.version || '';
  const osName = osInfo.name || '';
  const osVersion = osInfo.version || '';

  return {
    key,
    name,
    email,
    phone: phone ? phone : '',
    givenName,
    familyName,

    circleCiInfo,
    buildInfo,
    resolution,
    ramInfo,
    locale,
    githubLink,
    browserName,
    browserVersion,
    osName,
    osVersion,
  };
};
