import { useNavigate } from 'react-router-dom';
import { setIntercomUser } from 'src/util/intercom';
import { LoginFormData } from '../../../LoginMfa';
import { Company, Device, Employee } from '../../../../types';
import { assignIdLogRocket } from 'src/analytics/logRocket';
import { AuthUserDispatch } from 'src/react/_components/AuthUserProvider';
import { yemboApiCallWithErrorHandling } from 'src/queries';

type ResponseCompany = Omit<Company, 'eTag' | 'binaryImage'>;
type MfaChannels = 'email' | 'sms';
type LoginResponse = {
  device?: Device;
  employee: Pick<Employee, 'email' | 'phone' | 'isMfaRequired' | 'preferredMfaChannel'> & Partial<Employee>;
  isMfaCodeSent?: boolean;
  availableMfaChannels?: MfaChannels[];
  status?: Status[];
  passwordResetLink?: string;
};

type Status = {
  message?: string | null;
  type?: string;
};

const url = 'employee';

// On Successful login request
const onSuccessLogin = async (
  response: Response,
  navigate: ReturnType<typeof useNavigate>,
  setAuthUser: AuthUserDispatch
) => {
  const body = (await response.json()) as Record<string, unknown>;
  const { device, employee, availableMfaChannels, isMfaCodeSent } = body as LoginResponse;

  if (employee?.isMfaRequired) {
    if (!availableMfaChannels?.length) {
      throw new Error('No MFA channels available!');
    }

    if (!isMfaCodeSent) {
      throw new Error('Failed to send MFA code!');
    }

    setAuthUser((prev) => ({ ...prev, ...(employee as Employee) }));

    sessionStorage.setItem('has-sms-channel', availableMfaChannels.includes('sms').toString());

    navigate(isMfaCodeSent ? '/verify-mfa' : '/resend-mfa');
    return;
  }

  if (typeof device !== 'object' || !device || !('accessToken' in device)) {
    throw new Error('Access Token not in response!');
  }

  // save user key and access token in local storage so user is remembered on refresh
  localStorage.setItem('deviceKey', device.key);
  localStorage.setItem('employeeKey', device.userKey);
  localStorage.setItem('accessToken', device.accessToken);

  sessionStorage.removeItem('mfa-resend-channel');
  sessionStorage.removeItem('has-sms-channel');

  await readAuthUser(setAuthUser);
};

export class LoginError extends Error {
  constructor(message: string, readonly passwordResetLink?: string) {
    super(message);
  }
}

const onLoginFailure = async (response: Response) => {
  const body = (await response.json()) as Record<string, unknown>;
  const { status, passwordResetLink } = body as LoginResponse;
  const errorMessage = status?.reduce((acc, status) => `${acc} ${status.message ?? ''}`, '') ?? '';

  throw new LoginError(errorMessage, passwordResetLink);
};

export const login = async (
  formData: LoginFormData,
  timezone: string,
  navigate: ReturnType<typeof useNavigate>,
  setAuthUser: AuthUserDispatch
): Promise<LoginError | void> => {
  const data = { ...formData, timezone };

  const response = await yemboApiCallWithErrorHandling({
    method: 'PUT',
    url: `${url}/login`,
    body: JSON.stringify(data),
  });

  if (response.ok) {
    await onSuccessLogin(response, navigate, setAuthUser);
  } else {
    await onLoginFailure(response);
  }
};

export const verifyMfa = async (
  formData: LoginFormData,
  navigate: ReturnType<typeof useNavigate>,
  setAuthUser: AuthUserDispatch
): Promise<string | void> => {
  const response = await yemboApiCallWithErrorHandling({
    method: 'PUT',
    url: `${url}/verify-mfa`,
    body: JSON.stringify(formData),
  });

  if (response.ok) {
    await onSuccessLogin(response, navigate, setAuthUser);
  } else {
    await onLoginFailure(response);
  }
};

export const sendMfa = async (formData: LoginFormData, channel?: string): Promise<boolean> => {
  const data = { ...formData, channel };

  try {
    const response = await yemboApiCallWithErrorHandling({
      method: 'PUT',
      url: `${url}/send-mfa`,
      body: JSON.stringify(data),
    });
    return response.ok;
  } catch {
    return false;
  }
};

export async function signOut(navigate: ReturnType<typeof useNavigate>, setAuthUser: AuthUserDispatch): Promise<void> {
  setAuthUser(undefined);
  localStorage.clear();
  navigate('/');

  await yemboApiCallWithErrorHandling({
    method: 'PUT',
    url: `${url}/logout`,
  });
}

export const readAuthUser = async (setAuthUser: AuthUserDispatch): Promise<void> => {
  try {
    const response = await yemboApiCallWithErrorHandling({ method: 'GET', url });

    const body = (await response.json()) as Record<string, unknown>;

    if (!body.employee) {
      return;
    }

    const employee = body.employee as Record<string, unknown>;
    if (employee.companies && Array.isArray(employee.companies)) {
      const companyPromises = employee.companies.map((company: ResponseCompany) =>
        getCompanyWithLogo({ ...company, logo: company.logoUrl })
      );
      employee.companies = await Promise.all(companyPromises);
    }

    const { impersonatedRole, impersonatedCompanyKeys } = employee as Employee;

    localStorage.setItem('impersonatedCompanyKeys', (impersonatedCompanyKeys ?? '').toString());
    localStorage.setItem('impersonatedRole', impersonatedRole ?? '');

    delete employee.landingPageAccessToken;
    delete employee.twilioAccessToken;
    setAuthUser(employee as Employee);
    assignIdLogRocket(employee as Employee);
    setIntercomUser(employee as Employee);
  } catch {
    return;
  }
};

export async function getCompanyWithLogo(company: Company, loadedCompany?: Company): Promise<Company> {
  const updatedCompany = {
    ...loadedCompany,
    ...company,
  };
  if (!company.logo) {
    return updatedCompany;
  }

  try {
    const headers = company.eTag ? { 'If-None-Match': company.eTag } : undefined;
    const response = await fetch(company.logo, { headers });
    const type = response.headers.get('Content-Type');
    const responseETag = response.headers.get('ETag');

    if (response.status === 304 || !response.ok || !type || !responseETag) {
      return updatedCompany;
    }

    const byteArray = await response.arrayBuffer();

    return {
      ...company,
      eTag: responseETag,
      binaryImage: { byteArray, type },
    };
  } catch (error) {
    return updatedCompany;
  }
}
