import { FC } from 'react';

interface Props {
  heading: string;
  caption: string;
  showError?: boolean;
  errorMessage?: string | JSX.Element;
}

export const SectionHeader: FC<Props> = ({ heading, caption, showError, errorMessage }) => {
  return (
    <div className={`section-header ${showError ? 'has-error' : ''}`}>
      <h1>{heading}</h1>
      <p>{caption}</p>
      {showError && <div className='error-message'>{errorMessage}</div>}
    </div>
  );
};
