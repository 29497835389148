import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { yemboApiCall, yemboApiCallWithReturnValue } from '.';
import { Project, Region, Video } from '../types';
import { PROJECT_QUERY, REGION_MUTATION, REGION_QUERY } from './queryKeys';
import { DeleteResponse } from 'src/queries/specialty-items';

const requestRegion = async (regionKey: string): Promise<Region> => {
  const region = await yemboApiCallWithReturnValue<Region, string>({
    url: 'region',
    urlParams: new URLSearchParams({
      key: regionKey,
      expandedFields: '["video", "image", "tag", "scan"]',
    }),
    key: 'region',
  });

  if (!region) throw new Error('Region not found');

  return region;
};

export type ReanalyzeVideoResponse = {
  status: {
    message?: string | undefined;
    type?: string;
  }[];
  video: Video;
};

export const reanalyzeVideoRequest = async (region: Required<Pick<Region, 'videos'>>): Promise<void> => {
  const response = await yemboApiCall({
    url: 'video',
    method: 'POST',
    body: JSON.stringify({ ...region.videos[0] }),
  });

  const body = (await response.json()) as ReanalyzeVideoResponse;

  if (!response.ok) {
    const { message = 'Failed to reanalyze video!' } = body.status[0];
    throw new Error(message);
  }
};

export const useQueryRegion = (
  regionKey: string | null | undefined,
  options?: UseQueryOptions<Region, Error, Region, (string | null | undefined)[]>
): UseQueryResult<Region, Error> => {
  return useQuery({
    enabled: !!regionKey,
    ...options,
    queryKey: [REGION_QUERY, regionKey],
    queryFn: () => {
      if (!regionKey) throw new Error('Unreachable');
      return requestRegion(regionKey);
    },
  });
};

export type RegionsResponse = {
  status: {
    message?: string | undefined;
    type?: string;
  }[];
  regions: Region[];
};

const updateRegion = async (region: PartialRegion): Promise<void> => {
  const response = await yemboApiCall({
    url: 'region',
    method: 'PUT',
    body: JSON.stringify({ ...region }),
  });

  const body = (await response.json()) as RegionsResponse;

  if (!response.ok) {
    const { message = 'Failed to update region!' } = body.status[0];
    throw new Error(message);
  }
};

type MutationResult<A> = UseMutationResult<void, unknown, A, { previous?: Project }>;
type PartialRegion = Partial<Region> & Pick<Region, 'key' | 'surveyKey'>;

function useRegionMutation<A extends PartialRegion>(
  projectKey: Project['key'],
  mutate: (region: A) => Promise<void>,
  updateOptimistic: (region: A, previous: Region[]) => Region[],
  onError?: (error: unknown) => void
): MutationResult<A> {
  const queryClient = useQueryClient();

  const mutation: MutationResult<A> = useMutation({
    mutationKey: [REGION_MUTATION],
    mutationFn: async (region) => await mutate(region),
    onMutate: async (region) => {
      const queryKey = [PROJECT_QUERY, projectKey];
      await queryClient.cancelQueries(queryKey);

      const previous = queryClient.getQueryData<Project>(queryKey);

      if (previous) {
        const updatedProject = {
          ...previous,
          surveys: previous.surveys?.map((survey) => ({
            ...survey,
            regions:
              survey.key === region.surveyKey && survey.regions
                ? updateOptimistic(region, survey.regions)
                : survey.regions,
          })),
        };

        queryClient.setQueryData<Project>(queryKey, updatedProject);
      }

      return { previous };
    },

    onError: (error, region, context) => {
      onError?.(error);
      if (context?.previous) {
        queryClient.setQueryData<Project>([PROJECT_QUERY, projectKey], context.previous);
      }
    },
  });

  return {
    ...mutation,
  };
}

export const useUpdateRegion = (
  projectKey: Project['key'],
  onError?: (error: unknown) => void
): MutationResult<PartialRegion> =>
  useRegionMutation(
    projectKey,
    updateRegion,
    (region, previous) =>
      previous?.map((previousRegion) =>
        previousRegion.key === region.key ? { ...previousRegion, ...region } : previousRegion
      ),
    onError
  );

const deleteRegion = async (region: PartialRegion): Promise<void> => {
  const response = await yemboApiCall({
    url: 'region',
    method: 'DELETE',
    body: JSON.stringify({ ...region }),
  });

  const body = (await response.json()) as DeleteResponse;

  if (!response.ok) {
    const { message = 'Failed to delete item!' } = body.status[0];
    throw new Error(message);
  }
};

export const useDeleteRegion = (
  projectKey: Project['key'],
  onError?: (error: unknown) => void
): MutationResult<PartialRegion> =>
  useRegionMutation(
    projectKey,
    deleteRegion,
    (region, previous) => previous.filter((previousRegion) => previousRegion.key !== region.key),
    onError
  );

export const useReanalyzeRegionVideo = (
  projectKey: Project['key'],
  onError?: (error: unknown) => void
): MutationResult<PartialRegion & Required<Pick<Region, 'videos'>>> =>
  useRegionMutation<PartialRegion & Required<Pick<Region, 'videos'>>>(
    projectKey,
    reanalyzeVideoRequest,
    (region, previous) => {
      return previous.map((previousRegion) =>
        previousRegion.key === region.key ? { ...previousRegion, ...region } : previousRegion
      );
    },
    onError
  );
