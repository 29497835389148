import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const getRegionCode = ({ phone, country }) => {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phone, country);
    return phoneUtil.getRegionCodeForNumber(parsedNumber);
  } catch (e) {
    return country;
  }
};

export const isValidPhone = ({ phone, regionCode }) => {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phone, regionCode);

    return phoneUtil.isValidNumber(parsedNumber);
  } catch (e) {
    return false;
  }
};

export const toE164PhoneNumber = ({ phone, regionCode }) => {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phone, regionCode);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
  } catch (e) {
    return phone;
  }
};

export const toInternationalPhoneNumber = (phone) => {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phone);
    const countryCode = parsedNumber.getCountryCode();

    if (countryCode === 1) {
      return `+1 ${phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL)}`;
    }

    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
  } catch (e) {
    return phone;
  }
};
