import { BroadcastChannel } from 'broadcast-channel';

type Message = 'update_ready';

export function createMessageListener<T>(message: Message, onMessageIncome: (message: T) => void): () => void {
  const channel = new BroadcastChannel<T>(message);

  channel.addEventListener('message', onMessageIncome);
  return (): void => {
    channel.removeEventListener('message', onMessageIncome);
  };
}

export function sendMessage<T>(message: Message, data: T): void {
  const channel = new BroadcastChannel<T>(message);
  channel.postMessage(data);
}
