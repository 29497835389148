import {
  Button,
  Icon,
  Modal,
  ModalFooter,
  ModalHeader,
  SideNavBar,
  SideNavItemProps,
  YemboLogo,
} from '@yembo/yemblocks-core';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';
import { signOut } from 'src/react/_components/AuthUserProvider/api/auth';
import { useWindowDimensions } from 'src/react/_hooks/useWindowDimensions';
import { fadeIn, largeDesktopWidth, mobileWidth } from '../../_util';
import { CreateInsured } from '../CreateInsured';
import { InsuredConfigContext } from '../CreateInsured/_contexts/InsuredConfigProvider';

export const Sidebar = (): JSX.Element => {
  const navigate = useNavigate();
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const activeRole = authUser && (authUser?.impersonatedRole ?? authUser?.role);

  const { pathname } = useLocation();
  const basePath = pathname.split('/')[1];

  const { isInsuredFormOpen, toggleInsuredForm, closeInsuredForm } = useContext(InsuredConfigContext);

  const { width, height } = useWindowDimensions();
  const isMobileDevice = height <= mobileWidth || width <= mobileWidth;
  const shouldCollapseSidebar = width < largeDesktopWidth;

  const userName = authUser ? `${authUser.givenName} ${authUser.familyName}` : 'User';
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!shouldCollapseSidebar && !isMobileDevice);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isUserAvatarError, setIsUserAvatarError] = useState(false);

  const queryClient = useQueryClient();

  const toggleSidebar = useCallback(() => {
    if (isInsuredFormOpen) return closeInsuredForm();
    if (isMobileDevice) {
      // TO DO- Mobile ready
      // sidebarRef.current.classList.toggle('show-sidebar-mobile');
      // return;
    }
  }, [isInsuredFormOpen, closeInsuredForm, isMobileDevice]);

  useEffect(() => {
    const sidebarStateHandler = () => {
      const { clientWidth } = window.document.body;
      setIsSidebarOpen(clientWidth > 1600);
    };

    sidebarStateHandler();
    window.addEventListener('resize', sidebarStateHandler);

    return () => {
      window.removeEventListener('resize', sidebarStateHandler);
    };
  }, []);

  const topNavItems: SideNavItemProps[] = [
    {
      text: 'Insured Listing',
      icon: <Icon name='checkmark-list' />,
      isActive: basePath === 'insured' && !isInsuredFormOpen,
      onClick: (e) => {
        e.stopPropagation();

        if (isInsuredFormOpen) closeInsuredForm();

        navigate('/insured/all');
      },
      isSidebarOpen,
    },
    {
      text: 'Create Insured',
      icon: <Icon name='user-add' />,
      isActive: isInsuredFormOpen,
      onClick: (e) => {
        e.stopPropagation();
        toggleInsuredForm();
      },
      isSidebarOpen,
    },
  ];

  const adminNavItem: SideNavItemProps = {
    text: 'Admin',
    icon: <Icon name='shield' />,
    isActive: basePath === 'admin' && !isInsuredFormOpen,
    onClick: (e) => {
      e.stopPropagation();
      if (isInsuredFormOpen) closeInsuredForm();
      navigate(`/admin`);
    },
    isSidebarOpen,
  };

  const annotateNavItem: SideNavItemProps = {
    text: 'Annotate',
    icon: <Icon name='tag' />,
    isActive: basePath === 'annotate' && !isInsuredFormOpen,
    onClick: (e) => {
      e.stopPropagation();
      if (isInsuredFormOpen) closeInsuredForm();
      navigate(`/annotate`);
    },
    isSidebarOpen,
  };

  const reportingNavItem: SideNavItemProps = {
    text: 'Reports',
    icon: <Icon name='graph-bar' />,
    isActive: basePath === 'reporting' && !isInsuredFormOpen,
    onClick: (e) => {
      e.stopPropagation();
      if (isInsuredFormOpen) closeInsuredForm();
      navigate(`/reporting`);
    },
    isSidebarOpen,
  };

  const aiDashboardsItem: SideNavItemProps = {
    text: 'AI Dashboards',
    icon: <Icon name='shortcuts' />,
    isActive: basePath === 'ai-dashboards' && !isInsuredFormOpen,
    onClick: (e) => {
      e.stopPropagation();
      if (isInsuredFormOpen) closeInsuredForm();
      navigate(`/ai-dashboards`);
    },
    isSidebarOpen,
  };

  if (activeRole && ['admin', 'superadmin'].includes(activeRole)) topNavItems.push(adminNavItem);
  if (activeRole && ['annotator', 'superadmin'].includes(activeRole)) topNavItems.push(annotateNavItem);
  if (activeRole && ['superadmin'].includes(activeRole)) {
    topNavItems.push(reportingNavItem, aiDashboardsItem);
  }

  const bottomNavItems: SideNavItemProps[] = [
    {
      text: userName,
      icon:
        authUser?.photoUrl && !isUserAvatarError ? (
          <img src={authUser.photoUrl} alt='avatar' onLoad={fadeIn} onError={() => setIsUserAvatarError(true)} />
        ) : (
          <Icon name='user' />
        ),
      isActive: false,
      onClick: (e) => e.stopPropagation(),
      isSidebarOpen,
      id: 'auth-user',
    },
    {
      text: 'Settings',
      icon: <Icon name='settings' />,
      isActive: basePath === 'settings' && !isInsuredFormOpen,
      onClick: (e) => {
        e.stopPropagation();
        if (isInsuredFormOpen) closeInsuredForm();
        return basePath !== 'settings' && navigate(`/settings`);
      },
      isSidebarOpen,
    },
    {
      text: 'Help',
      icon: <Icon name='help' />,
      isActive: basePath === 'help',
      onClick: (e) => {
        e.stopPropagation();
        return basePath !== 'help' && window.open('https://docs.yembo.ai/en/');
      },
      isSidebarOpen,
    },
    {
      text: 'Log Out',
      icon: <Icon name='power-switch' />,
      isActive: false,
      onClick: (e) => {
        e.stopPropagation();
        setIsLogoutModalOpen(true);
      },
      isSidebarOpen,
    },
  ];

  return (
    <>
      <SideNavBar
        ref={sidebarRef}
        onClick={toggleSidebar}
        topItems={topNavItems}
        bottomItems={bottomNavItems}
        isSidebarOpen={isSidebarOpen}
        logo={<YemboLogo />}
      />
      <CreateInsured />
      {isLogoutModalOpen && (
        <Modal className='logout-modal' isDismissible={false}>
          <ModalHeader headerText='Logout'>Are you sure you want to log out from this device?</ModalHeader>
          <ModalFooter>
            <Button className='yb-tertiary-button' text='Cancel' onClick={() => setIsLogoutModalOpen(false)} />
            <Button
              className='yb-danger-button logout-button'
              text='Logout'
              onClick={() => {
                queryClient.clear();
                return signOut(navigate, setAuthUser);
              }}
            />
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
