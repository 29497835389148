import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { logger } from 'src/util/logger';
import { apiUrl } from '../util/env';
export type ResponseJson<T, K extends string> = {
  [key in K]: T;
} & { status: [{ type: string }] };

export const queryCache = new QueryCache({
  onError: (error) => logger.error(error),
});

export const mutationCache = new MutationCache({
  onError: (error, variables) => {
    logger.error(error);
    logger.info('Variables: ', variables);
  },
});

queryCache.subscribe((query) => {
  if (!query) {
    return logger.log('Empty Query');
  }

  const {
    type,
    query: { state, queryHash },
  } = query;
  if (['added', 'removed', 'updated'].includes(query.type)) {
    logger.log('Query: ', { type, queryHash, state });
  }
});
mutationCache.subscribe((event) => {
  if (!event.mutation) return;

  const { options, state, mutationId } = event.mutation;

  if (state.data) {
    logger.log('Mutation: ', { mutationId, options, state });
  }
});

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: 10 * 60 * 1000, // 5 min by default
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});

export type Url = { url: string; urlParams?: URLSearchParams | string };

const isResponseObject = <T, K extends string>(object: unknown, key: K): object is ResponseJson<T, K> =>
  Boolean(object && typeof object === 'object' && key in object);

export class YemboApiError extends Error {
  constructor(readonly response: Response, readonly json?: unknown) {
    super(response.statusText);
  }
}

type YemboApiCallOptions = RequestInit & Url;

export const yemboApiCallWithReturnValue = async <T, K extends string>(
  prop: YemboApiCallOptions & { key: K }
): Promise<T | null> => {
  const response = await yemboApiCall(prop);

  if (response.ok) {
    if (response.status === 204) return null;
    const responseJson = (await response.json()) as unknown;
    if (isResponseObject<T, K>(responseJson, prop.key)) {
      return responseJson[prop.key];
    }

    throw new SyntaxError('Invalid response JSON');
  } else {
    let errorJson: unknown;

    try {
      errorJson = await response.json();
    } catch {
      errorJson = undefined;
    }

    throw new YemboApiError(response, errorJson);
  }
};

export const yemboApiCallWithFormData = async (
  prop: YemboApiCallOptions & { withoutAccessToken?: boolean }
): Promise<Response> => {
  const token = window.localStorage.getItem('accessToken');
  const impersonatedRole = window.localStorage.getItem('impersonatedRole');
  const impersonatedCompanyKeys = window.localStorage.getItem('impersonatedCompanyKeys');

  const authHeader: HeadersInit = token && !prop.withoutAccessToken ? { 'X-Employee-Access-Token': token } : {};
  const impersonationHeaders: HeadersInit = {};

  if (impersonatedRole) impersonationHeaders['X-Role'] = impersonatedRole;
  if (impersonatedCompanyKeys) impersonationHeaders['X-Company-Keys'] = impersonatedCompanyKeys;

  const response = await fetch(`${apiUrl}/${prop.url}${prop.urlParams ? `?${prop.urlParams.toString()}` : ''}`, {
    ...prop,
    headers: {
      ...authHeader,
      ...impersonationHeaders,
      ...prop.headers,
    },
  });

  // Clear data from the local storage if the user is unauthorized
  if (response.status === 401) window.localStorage.clear();

  return response;
};

export const yemboApiCall = async (prop: YemboApiCallOptions & { withoutAccessToken?: boolean }): Promise<Response> => {
  const token = window.localStorage.getItem('accessToken');
  const impersonatedRole = window.localStorage.getItem('impersonatedRole');
  const impersonatedCompanyKeys = window.localStorage.getItem('impersonatedCompanyKeys');

  const authHeader: HeadersInit = token && !prop.withoutAccessToken ? { 'X-Employee-Access-Token': token } : {};
  const impersonationHeaders: HeadersInit = {};

  if (impersonatedRole) impersonationHeaders['X-Role'] = impersonatedRole;
  if (impersonatedCompanyKeys) impersonationHeaders['X-Company-Keys'] = impersonatedCompanyKeys;

  const response = await fetch(`${apiUrl}/${prop.url}${prop.urlParams ? `?${prop.urlParams.toString()}` : ''}`, {
    ...prop,
    headers: {
      ...authHeader,
      ...impersonationHeaders,
      ...prop.headers,
      'Content-Type': 'application/json',
    },
  });

  // Clear data from the local storage if the user is unauthorized
  if (response.status === 401) window.localStorage.clear();

  return response;
};

export const yemboApiCallWithErrorHandling = async (
  prop: YemboApiCallOptions & { withoutAccessToken?: boolean }
): Promise<Response> => {
  let response: Response | undefined;

  try {
    response = await yemboApiCall(prop);
  } catch (exception) {
    if (exception instanceof Error) throw exception;

    throw new Error('Unexpected error');
  }

  if (!response.ok) {
    const errorJson: unknown = await response.json();
    throw new YemboApiError(response, errorJson);
  }

  return response;
};

export const invalidateIfAllMutationsDone = (
  queryClient: QueryClient,
  queryKey: (string | undefined)[],
  mutationKey: string[]
) => {
  if (
    queryClient.isMutating({
      predicate: (mutation) => !!(mutation.options.mutationKey?.toString() === mutationKey.toString()),
    }) === 1
  ) {
    queryClient.invalidateQueries(queryKey);
  }
};
