import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

export type DateFormatStringProps = {
  date?: string | null;
  country?: string | undefined;
  showShortened?: boolean;
  showDateWithTime?: boolean;
  formatString?: string;
};

// Required for correct work daysj advancedFormat extension in Account Activation Flow
export const getDateInDaysOrderFormat = (): string => dayjs().format('ddd MMMM Do');

export const getDateFormatString = ({ country, showShortened = false }: DateFormatStringProps): string => {
  const monthFirstCountries = ['US', 'CA']; // manually adding countries that use month first formatting. Can't use moment 'L' formatting with google's date picker
  const showMonthFirst = country && monthFirstCountries.includes(country);

  if (showMonthFirst) {
    return showShortened ? 'M/D' : 'MM/DD/YYYY';
  }
  return showShortened ? 'D/M' : 'DD/MM/YYYY';
};

export const renderDateText = ({ date, showDateWithTime, formatString }: DateFormatStringProps): string => {
  const calendarOptions = {
    sameDay: '[Today], h:mm a',
    nextDay: '[Tomorrow], h:mm a',
    lastDay: '[Yesterday], h:mm a',
    sameElse: formatString,
  };

  const isValid = dayjs(date).isValid();
  const yesterday = dayjs().subtract(1, 'day').startOf('day');
  const sevenDaysAgo = dayjs().subtract(7, 'day').startOf('day');
  const isBetween = dayjs(date).startOf('day').isBetween(sevenDaysAgo, yesterday);
  const showRelativeDate = isBetween && showDateWithTime;
  const relativeDate = dayjs(date).fromNow();
  const dateWithTime = dayjs(date).calendar(null, calendarOptions);
  const monthDayYearDate = dayjs(date).format(formatString);

  if (!isValid) {
    return '--';
  } else if (showRelativeDate) {
    return relativeDate;
  } else if (showDateWithTime) {
    return dateWithTime;
  } else {
    return monthDayYearDate;
  }
};
