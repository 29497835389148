import { Step } from './components/Step';

interface Props {
  stepsCompleted: number;
}
export const IconProgressBar = ({ stepsCompleted }: Props): JSX.Element => {
  return (
    <div className='progress-bar-wrap'>
      <div className='progress-bar-position'>
        <Step
          iconName={stepsCompleted > 0 ? 'checkmark' : 'lock'}
          isLineAppended={true}
          isActive={true}
          isLineActive={stepsCompleted > 0}
        />
        <Step
          iconName={stepsCompleted > 1 ? 'checkmark' : 'phone'}
          isLineAppended={true}
          isActive={stepsCompleted > 0}
          isLineActive={stepsCompleted > 1}
        />
        <Step
          iconName={stepsCompleted > 2 ? 'checkmark' : 'invite'}
          isLineAppended={false}
          isActive={stepsCompleted > 1}
          isLineActive={false}
        />
      </div>
    </div>
  );
};
