import { isEmptyStringOrWhitespace } from './isEmptyStringOrWhitespace';

/**
 * @typedef {import('src/redux/types').Company} Company
 * @param {{ company?: Company | null, companies: Company[]} | { company: Company, companies?: Company[]}} companies
 */

export const getDefaultCountry = ({ company, companies }) => {
  if (company?.defaultCountry) return company.defaultCountry;
  if (companies && companies[0]?.defaultCountry) return companies[0].defaultCountry;

  return 'US';
};

export const isNullOrWhitespace = (value) => {
  if (isNull(value)) return true;
  if (isUndefined(value)) return true;
  if (isString(value)) return isEmptyStringOrWhitespace(value);
  return false;
};

export const isString = (value) => typeof value === 'string';
export const isUndefined = (value) => value === undefined;
export const isArray = (value) => Array.isArray(value);
export const isEmptyArray = (value) => isArray(value) && !value.length;
export const isNull = (value) => value === null;

export const isNumeric = (value) => {
  const isNumber = typeof value === 'number';
  const isNonEmptyString = typeof value === 'string' && !isEmptyStringOrWhitespace(value);
  return isNumber || isNonEmptyString ? !isNaN(value) : false;
};

export const isEmptyObject = (object) => Object.keys(object).length === 0;

/**
 * Strictly speaking, null is an Object since typeof(null) === 'object'. However, there are many cases of
 * other functions that expect objects and will throw an exception if null is provided, e.g.,
 * Object.keys(null). So, isObject(null) returns false.
 */
export const isObject = (value) => !isNull(value) && typeof value === 'object';
export const isBoolean = (value) => typeof value === 'boolean';

export const isValidEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
export const isValidWebhookUrl = (value) =>
  /(https):(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(|([\w#!:.?+=&%@!\-/]))?/.test(value);

export const isValidPassword = (value) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#!@#$%^&*()_+=~,.;'"/:?><[\]{}\\|-]{8,}$/.test(value);

export const doesPasswordContainNumber = (value) => /^(?=.*[0-9])/.test(value);
export const doesPasswordContainLowerCaseLetter = (value) => /^(?=.*[a-z])/.test(value);
export const doesPasswordContainUpperCaseLetter = (value) => /^(?=.*[A-Z])/.test(value);

export const isMatchedConfirmPassword = (values) => values.password === values.confirmPassword;

export const isYemboEmail = ({ email }) => isString(email) && email.endsWith('@yembo.ai');

export const getFinalObjectChanges = ({ initial, final }) => {
  const arrayOfChanges = Object.entries(final).filter(([key]) => initial[key] !== final[key]);
  const hasChanged = arrayOfChanges.length > 0;

  let changes = null;

  if (hasChanged) {
    changes = {};
    arrayOfChanges.reduce((accum, [key, value]) => {
      accum[key] = value;
      return accum;
    }, changes);
  }
  return changes;
};

export const isValidIntegerOrFloat = (value) => {
  if (isNumeric(value)) return true;
  if (!isString(value)) return false;

  const strippedValue = value.replace('.', '');

  if (strippedValue.includes('.')) return false;

  return isNumeric(strippedValue);
};
// When shouldDeepCompare = false, objects only compared to its first level properties.
export const didObjectChange = ({ initial, final, shouldDeepCompare = false }) => {
  return Object.keys(final).some((key) => {
    const initialValue = initial[key];
    const finalValue = final[key];
    const areObjects = isObject(finalValue) && isObject(initialValue);
    const shouldCompareNestedObjects = areObjects && shouldDeepCompare;
    if (shouldCompareNestedObjects) {
      const areSameLength = Object.keys(initialValue).length === Object.keys(finalValue).length;
      if (!areSameLength) return true;
      return didObjectChange({ initial: initialValue, final: finalValue });
    } else {
      return initialValue !== finalValue;
    }
  });
};
