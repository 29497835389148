import { BannerNotification, BannerNotificationType } from '@yembo/yemblocks-core';
import { createContext, useState } from 'react';

export type Message = {
  text: string;
  type: BannerNotificationType;
  duration?: number;
  persistent?: boolean;
  dark?: boolean;
};

type NotificationsValue = {
  showSuccess: (text: string, dark?: boolean) => void;
  showInfo: (text: string, dark?: boolean) => void;
  showWarning: (text: string, dark?: boolean) => void;
  showError: (text: string, dark?: boolean, persistent?: boolean) => void;
  showMessage: (message: Message) => void;
};

type MessageEntity = Message & {
  key: number;
  duration: number;
};

const DEFAULT_DURATION = 5000;

export const Notifications = createContext<NotificationsValue>({
  showSuccess: () => undefined,
  showInfo: () => undefined,
  showWarning: () => undefined,
  showError: () => undefined,
  showMessage: () => undefined,
});

export function NotificationsProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [messages, setMessages] = useState<MessageEntity[]>([]);

  const removeMessage = (message: MessageEntity) => setMessages((messages) => messages.filter((m) => m !== message));

  const showMessage = (message: Message) => {
    const messageEntity = {
      ...message,
      key: performance.now() + Math.random(),
      duration: message.duration ?? DEFAULT_DURATION,
    };
    setMessages((messages) => [...messages, messageEntity]);
  };

  return (
    <Notifications.Provider
      value={{
        showMessage,
        showSuccess: (text: string, dark?: boolean) => showMessage({ text, type: 'success', dark }),
        showInfo: (text: string, dark?: boolean) => showMessage({ text, type: 'info', dark }),
        showWarning: (text: string, dark?: boolean) => showMessage({ text, type: 'warning', dark }),
        showError: (text: string, dark?: boolean, persistent?: boolean) =>
          showMessage({ text, type: 'error', dark, persistent }),
      }}
    >
      {children}
      {messages.length > 0 && (
        <div
          className={`messages-placeholder ${
            messages[0].dark ? 'kepler-editor-notification' : 'kepler-backoffice-notification'
          }`}
        >
          {messages.map((messageEntity) => {
            const { key, text, type, duration, persistent, dark } = messageEntity;
            return (
              <BannerNotification
                key={key}
                type={type}
                text={text}
                onClose={() => removeMessage(messageEntity)}
                theme={dark ? 'dark' : 'light'}
                className={'kepler-notification'}
                timeout={persistent ? undefined : duration}
              />
            );
          })}
        </div>
      )}
    </Notifications.Provider>
  );
}
