import { Button, RadioButton } from '@yembo/yemblocks-core';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthUserContext } from 'src/react/_components/AuthUserProvider';
import { toInternationalPhoneNumber } from 'src/util/validate_data_get_state';
import { LoginFormData } from '../';
import { sendMfa } from '../../_components/AuthUserProvider/api/auth';
import { SectionHeader } from '../SectionHeader';
import { useSubmitOnEnterKey } from '../_util/useEnterKeySubmit';

interface Props {
  formData: LoginFormData;
}

export const ResendMfa = ({ formData }: Props): JSX.Element => {
  const navigate = useNavigate();

  const { authUser } = useContext(AuthUserContext);
  const { email, phone, preferredMfaChannel } = authUser ?? {};

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [channel, setChannel] = useState<string | undefined>(preferredMfaChannel || undefined);

  const onChangeChannel = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setChannel(value);
  };

  const hasSmsChannel = (JSON.parse(sessionStorage.getItem('has-sms-channel') as string) as boolean) || false;

  const form = (
    <form>
      <RadioButton
        label={
          <div>
            <p>Email code to:</p>
            <p className='bold'>{email}</p>
          </div>
        }
        name='channel'
        value='email'
        checked={channel === 'email'}
        onChange={onChangeChannel}
      />
      {hasSmsChannel && (
        <RadioButton
          label={
            <div>
              <p>Text code to:</p>
              <p className='bold'>{phone ? toInternationalPhoneNumber(phone) : ''}</p>
            </div>
          }
          name='channel'
          value='sms'
          checked={channel === 'sms'}
          onChange={onChangeChannel}
        />
      )}
    </form>
  );

  const onResend = useCallback(() => {
    const resendWithLoading = async (): Promise<void> => {
      setIsLoading(true);
      const isSent = await sendMfa(formData, channel);

      if (isSent) {
        channel && sessionStorage.setItem('mfa-resend-channel', channel);
        navigate('/verify-mfa');
      } else {
        setIsLoading(false);
      }
    };

    resendWithLoading();
  }, [formData, channel, navigate]);

  useSubmitOnEnterKey({ onSubmit: onResend });

  return (
    <div className='resend-mfa'>
      <SectionHeader heading='Resend code' caption='How would you like to receive your one-time code?' />
      {form}
      <Button text='Resend' className='yb-primary-button' isLoading={isLoading} onClick={onResend} />
      <Button
        text='Use backup code'
        className='yb-tertiary-button'
        size='small'
        onClick={() => navigate('/verify-backup-code')}
        isDisabled={isLoading}
      />
    </div>
  );
};
