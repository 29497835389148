// "base environment" refers to the API environment without its regional suffix (i.e. `prod` instead of `prod-sg`)
export const getBaseEnvironments = ({ country }: { country: string }) => {
  switch (country) {
    case 'United States':
      return ['prod', 'staging', 'artisan', 'dev'];
    case 'India':
      return ['prod'];
    default:
      return ['prod'];
  }
};
