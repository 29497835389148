import { isNullOrWhitespace } from 'src/util/validate_data';
import { Employee, Insured } from '../../../types';

export const getFullName = (user: Employee | Insured, defaultText?: string): string => {
  try {
    const { givenName, familyName } = user;
    const hasName = !(isNullOrWhitespace(givenName) && isNullOrWhitespace(familyName));
    if (hasName) {
      return `${givenName} ${familyName}`;
    } else {
      throw new Error('No name provided');
    }
  } catch (e) {
    return defaultText ?? '--';
  }
};
